import { gql, useQuery } from '@apollo/client';
import { LockIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, HStack, Select, Skeleton, Text, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import DashboardMap from 'components/Map/DashboardMap';
import NotFound from 'components/Pages/NotFound';
import { RenderError } from 'components/Pages/RenderError';
import HeadlineStats from 'components/Site/Headline/Stats/HeadlineStats';
import Weather from 'components/Site/Headline/Weather/Weather';
import SiteStatusMessages from 'components/Site/SiteStatusMessages';
import {
  GetFishGroupsWithPopulationIdsQuery,
  GetSitePopulationsQuery,
  Site
} from 'graphql/generated';
import { GET_SITE } from 'graphql/globalQueries';
import { FC, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Notice } from 'components/Notice';
import { QueryContextProvider } from 'contexts/QueryContext';
import { FishGroupSpan } from './FishGroupsLayout';

const GET_SITE_POPULATIONS_QUERY = gql`
  query GetSitePopulations($populationsWhere: PopulationWhere!) {
    populations(where: $populationsWhere) {
      SmbId
      PopulationId
      StartTime
      EndTime
      Container
    }
  }
`;

const GET_FISH_GROUPS_QUERY = gql`
  query GetFishGroupsWithPopulationIds($projectId: Int!, $populationIds: [String!]!) {
    fishGroups(projectId: $projectId, populationIds: $populationIds) {
      id
      name
      populationIds
    }
  }
`;

export const TabErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <Center width={'100vw'} height={'100vh'}>
        <RenderError />
      </Center>
    }
    showDialog={false}>
    {children}
  </ErrorBoundary>
);

const SiteLayout = () => {
  const { siteId } = useParams();
  const { data, loading, error } = useQuery<{ site: Site }>(GET_SITE, {
    skip: !siteId,
    variables: {
      id: Number(siteId)
    }
  });

  const {
    data: populationData,
    loading: populationLoading,
    error: populationError
  } = useQuery<GetSitePopulationsQuery>(GET_SITE_POPULATIONS_QUERY, {
    skip: !data?.site?.smbId,
    variables: {
      populationsWhere: {
        SmbId: data?.site?.smbId,
        EndTime: null
      }
    }
  });

  const {
    data: fishGroupData,
    loading: fishGroupsLoading,
    error: fishGroupsError
  } = useQuery<GetFishGroupsWithPopulationIdsQuery>(GET_FISH_GROUPS_QUERY, {
    skip: !(populationData?.populations?.length > 0),
    variables: {
      projectId: data?.site?.projectId,
      populationIds: populationData?.populations?.map((p) => p.PopulationId)
    }
  });

  const all = { label: 'All', value: 'All' };

  const fishGroupOptions = useMemo(() => {
    if (!fishGroupData?.fishGroups?.length) return [all];

    return [
      all,
      ...fishGroupData.fishGroups.map((fg) => ({ label: fg.name, value: fg.id.toString() }))
    ];
  }, [fishGroupData]);

  const [fishGroupFilter, setFishGroupFilter] = useState<'All' | number>('All');

  const fishGroupQueryFilter: FishGroupSpan[] = useMemo(() => {
    if (fishGroupFilter === 'All') return [];

    const fishGroup = fishGroupData.fishGroups.find((fg) => fg.id === Number(fishGroupFilter));

    const populations = populationData.populations.filter((pd) =>
      fishGroup.populationIds.includes(pd.PopulationId)
    );

    return populations.map((p) => ({
      start: new Date(p.StartTime),
      end: p?.EndTime ? new Date(p.EndTime) : new Date(),
      siteSmbId: p.SmbId,
      sublocation: `cage-${p.Container}`
    }));
  }, [fishGroupFilter, populationData, fishGroupData]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 799px)' });

  const useStatusMessages = useMemo(() => {
    if (!data?.site) return false;

    return !(data?.site.siteLabel === 'ASC' || data?.site.archived);
  }, [data]);

  if (loading) {
    return <></>;
  }

  if (!loading && (data?.site === null || error)) {
    return <NotFound />;
  }

  return (
    <VStack>
      <QueryContextProvider site={data?.site} fishGroupSpans={fishGroupQueryFilter}>
        {useStatusMessages && (
          <SiteStatusMessages
            siteHasGdb={data.site.hasGdb}
            siteName={data.site.name}
            siteSmbId={data.site.smbId}
          />
        )}
        <Flex w="100%" minH="300px" direction={isTabletOrMobile ? 'column' : 'row'}>
          <TabErrorBoundary>
            <Box
              w={isTabletOrMobile ? '100%' : '400px'}
              minH="300px"
              position="relative"
              borderRadius="base"
              boxShadow="base">
              <DashboardMap activeSiteId={data.site.id} />
            </Box>
            {data.site.archived ? (
              <VStack m="1rem" alignItems="center" w="100%">
                <Text fontSize="3xl">{data.site.name}</Text>
                <VStack
                  data-cypress="archived-header-info"
                  justifyContent="center"
                  w="100%"
                  h="100%"
                  border="1px"
                  borderColor="yellow.500"
                  borderRadius="3px">
                  <LockIcon boxSize={8} color="yellow.500" />
                  <Text fontSize="xl">This site is Archived.</Text>
                  <Text textAlign="center" w="75%">
                    This site is archived and will not receive any new data or updates. Please
                    contact Scoot Science if you need this site active again.
                  </Text>
                  <Text textAlign="center" w="75%">
                    You can still access your old data through Explore.
                  </Text>
                </VStack>
              </VStack>
            ) : (
              <VStack w="100%">
                <HStack>
                  <Text fontSize="3xl">{data.site.name} -</Text>
                  {!populationError &&
                  !fishGroupsError &&
                  !fishGroupsLoading &&
                  !populationLoading ? (
                    <Select
                      borderColor="blue.200"
                      borderBottomWidth="2px"
                      fontSize="lg"
                      variant="flushed"
                      w="200px"
                      value={fishGroupFilter}
                      onChange={(e) => setFishGroupFilter(e.currentTarget.value as number | 'All')}>
                      {fishGroupOptions.map((fgo) => (
                        <option key={fgo.value} value={fgo.value}>
                          {fgo.label}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Skeleton w="200px" h="40px" />
                  )}
                </HStack>
                <HeadlineStats site={data.site} />
              </VStack>
            )}
          </TabErrorBoundary>
        </Flex>
        {!data.site.archived && !isTabletOrMobile && (
          <Box w="100%">
            <Weather smbSiteId={data.site.smbId} lat={data.site.lat} lon={data.site.lon} />
          </Box>
        )}

        {isTabletOrMobile ? (
          <Notice py="100px" textAlign="center">
            <Text>
              Please visit SeaState on a wide screen device for the full experience. Small screen
              devices currently not supported.
            </Text>
          </Notice>
        ) : (
          <Box pt="20px" pb="20px" w="100%" id={'sitepage'}>
            <TabErrorBoundary>
              <Outlet context={data.site} />
            </TabErrorBoundary>
          </Box>
        )}
      </QueryContextProvider>
    </VStack>
  );
};

export default SiteLayout;
