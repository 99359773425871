import { DateRange, Query, ResultSet, TimeDimensionGranularity } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { QueryContext } from 'contexts/QueryContext';
import { roundToNearestMinutes } from 'date-fns';
import { groupBy, mean, uniq } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { HiResHydrographyCubeDatum, cubeDimensionToPlotName } from '../CubeTypes';
import { getResultSetsDateRange } from '../getResultSetsDateRange';
import { ChartSettings } from './Chart';

const scrubZeroNan = (values: number[]) => values.filter((v) => v != 0 && !Number.isNaN(v));

export const plotNameToConfigParam = {
  oxygenSat: 'oxygen_sat',
  oxygenConcentration: 'oxygen_mgl',
  waterTemp: 'water_temp_c',
  depth: 'depth',
  salinity: 'salinity_psu'
};

export type HiResHydrographyStructure = {
  [sensorType in keyof typeof plotNameToConfigParam]: {
    // Includes barge at depth. ex. "barge-15m"
    [sublocation: string]: {
      time: string;
      value: number;
    }[];
  };
};

type useConditionDataProps = {
  settings: ChartSettings;
  granularity: TimeDimensionGranularity;
  dateRange: DateRange;
  skip: boolean;
  refreshInterval?: number;
  groupByMinute?: number;
  onDataLoaded?: (resultSets: ResultSet<any>[], dateRange: [string, string]) => void;
};

const useConditionData = ({
  settings,
  granularity,
  dateRange,
  skip,
  refreshInterval,
  groupByMinute,
  onDataLoaded
}: useConditionDataProps) => {
  const { contextFiltersFor, contextDateRange, contextDimensionsFor, datumKey } =
    useContext(QueryContext);
  const dimensions = [
    ...contextDimensionsFor({ cube: 'TessSensorHydrography' }),
    'TessSensorHydrographyLookup.depth'
  ];

  const [data, setData] = useState<HiResHydrographyStructure>(null);

  const transform = (
    timeDimension: string,
    data: HiResHydrographyCubeDatum[]
  ): HiResHydrographyStructure => {
    const inPenData = Object.keys(cubeDimensionToPlotName).reduce((sensorAcc, sensorDimension) => {
      const bySublocation = groupBy(data, (d) => datumKey(d, dimensions));

      const sublocationTimes = Object.entries(bySublocation).reduce(
        (acc, [sublocation, rawDataAtLocation]) => {
          let dataAtLocation = rawDataAtLocation;

          //Downsample aggregate to groupByMinute
          if (timeDimension === 'minute' && groupByMinute) {
            const fiveGroups = groupBy(rawDataAtLocation, (d) => {
              return roundToNearestMinutes(
                Date.parse(d[`TessSensorHydrography.measuredAt.minute`] + 'Z'),
                {
                  nearestTo: groupByMinute
                }
              ).toISOString();
            });

            dataAtLocation = Object.entries(fiveGroups).reduce(
              (acc, [fiveInterval, dataAtInterval]) => {
                return [
                  ...acc,
                  {
                    [sublocation]: sublocation,
                    'TessSensorHydrography.depth':
                      dataAtInterval?.[0]['TessSensorHydrographyLookup.depth'],
                    'TessSensorHydrography.measuredAt': fiveInterval,
                    'TessSensorHydrography.measuredAt.minute': fiveInterval,
                    'TessSensorHydrography.waterTempAvg': mean(
                      scrubZeroNan(
                        dataAtInterval.map((d) => Number(d['TessSensorHydrography.waterTempAvg']))
                      )
                    ),
                    'TessSensorHydrography.oxygenSaturationAvg': mean(
                      scrubZeroNan(
                        dataAtInterval.map((d) =>
                          Number(d['TessSensorHydrography.oxygenSaturationAvg'])
                        )
                      )
                    ),
                    'TessSensorHydrography.oxygenConcentrationAvg': mean(
                      scrubZeroNan(
                        dataAtInterval.map((d) =>
                          Number(d['TessSensorHydrography.oxygenConcentrationAvg'])
                        )
                      )
                    ),
                    'TessSensorHydrography.measuredDepthAvg': mean(
                      scrubZeroNan(
                        dataAtInterval.map((d) =>
                          Number(d['TessSensorHydrography.measuredDepthAvg'])
                        )
                      )
                    ),
                    'TessSensorHydrography.salinityAvg': mean(
                      scrubZeroNan(
                        dataAtInterval.map((d) => Number(d['TessSensorHydrography.salinityAvg']))
                      )
                    )
                  }
                ];
              },
              [] as HiResHydrographyCubeDatum[]
            );
          }

          acc[sublocation] = dataAtLocation.map((d) => ({
            time: d[`TessSensorHydrography.measuredAt.${timeDimension}`],
            value: d[`TessSensorHydrography.${sensorDimension}`]
          }));

          acc[sublocation] = acc[sublocation].filter((v) => !Number.isNaN(v.value));

          return acc;
        },
        {}
      );

      if (Object.keys(sublocationTimes).length > 0) {
        sensorAcc[cubeDimensionToPlotName[sensorDimension]] = {
          ...sensorAcc[cubeDimensionToPlotName[sensorDimension]],
          ...sublocationTimes
        };
      }

      return sensorAcc;
    }, {} as HiResHydrographyStructure);
    return inPenData;
  };

  const baseCubeQuery: Query = {
    measures: settings.measures
      ? settings.measures.map((m) => `TessSensorHydrography.${m}`)
      : [
          'TessSensorHydrography.waterTempAvg',
          'TessSensorHydrography.oxygenSaturationAvg',
          'TessSensorHydrography.oxygenConcentrationAvg',
          'TessSensorHydrography.salinityAvg',
          'TessSensorHydrography.measuredDepthAvg'
        ],
    dimensions: settings.site?.smbId
      ? ['TessSensorHydrographyLookup.sublocation', 'TessSensorHydrographyLookup.depth']
      : ['Site.id', 'TessSensorHydrographyLookup.sublocation', 'TessSensorHydrographyLookup.depth'],
    filters: contextFiltersFor({
      cube: 'TessSensorHydrography',
    }),
    order: { 'TessSensorHydrography.measuredAt': 'desc' },
    timezone: settings.project.timezone
  };

  const {
    isLoading: hourlyLoading,
    error: hourlyError,
    resultSet: hourlyResult,
    refetch: hourlyRefetch
  } = useCubeQuery(
    {
      ...baseCubeQuery,
      timeDimensions: [
        {
          dimension: 'TessSensorHydrography.measuredAt',
          granularity,
          dateRange: contextDateRange(dateRange)
        }
      ],
      order: { 'TessSensorHydrography.measuredAt': 'desc' },
      timezone: settings.project.timezone,
      limit: 50000
    },
    { skip }
  );

  const {
    isLoading: hiresLoading,
    error: hiresError,
    resultSet: hiresResult,
    refetch: hiresRefetch
  } = useCubeQuery(
    {
      ...baseCubeQuery,
      timeDimensions: [
        {
          dimension: 'TessSensorHydrography.measuredAt',
          granularity: 'minute',
          dateRange: 'from 360 minutes ago to now'
        }
      ],
      limit: 50000
    },
    { skip: !settings.useHires }
  );

  useEffect(() => {
    if ((!hiresResult && settings.useHires) || !hourlyResult) return;

    const hourly = hourlyResult.rawData();
    const hires = hiresResult?.rawData() ?? [];

    if (onDataLoaded && !hiresLoading && !hourlyLoading && hiresResult) {
      onDataLoaded(
        [hourlyResult, hiresResult],
        getResultSetsDateRange(hiresResult, settings.project.timezone)
      );
    }

    const transformedHour = transform(granularity, hourly);

    const transformedMinute = transform('minute', hires);

    const allSensorTypes = uniq([
      ...Object.keys(transformedHour),
      ...Object.keys(transformedMinute)
    ]);

    const allSublocations = uniq([
      ...Object.values(transformedHour).flatMap((o) => Object.keys(o)),
      ...Object.values(transformedMinute).flatMap((o) => Object.keys(o))
    ]);

    const combined = allSensorTypes.reduce((acc, sensorType) => {
      acc[sensorType] = allSublocations.reduce((subAcc, sub) => {
        const hourAtLocation = transformedHour?.[sensorType]?.[sub] ?? [];
        const minuteAtLocation = transformedMinute?.[sensorType]?.[sub] ?? [];

        subAcc[sub] = [...minuteAtLocation, ...hourAtLocation];

        return subAcc;
      }, {});
      return acc;
    }, {} as HiResHydrographyStructure);

    setData(combined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiresResult, hiresLoading, hourlyLoading, hourlyResult]);

  useEffect(() => {
    if (refreshInterval) {
      const interval = setInterval(() => {
        hourlyRefetch();
        hiresRefetch();
      }, refreshInterval);

      return () => clearInterval(interval);
    }
  }, [refreshInterval, hourlyRefetch, hiresRefetch]);

  return {
    isLoading: hourlyLoading || hiresLoading,
    error: hourlyError || hiresError,
    data
  };
};

export default useConditionData;
