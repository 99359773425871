import { FishGroupsOutletContext } from 'components/Layouts/FishGroupsLayout';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';
import { fishGroupTabs } from './FishGroupsTabId';

const FishGroupsId = () => {
  const project = useContext(ProjectContext);
  const { fishGroup } = useOutletContext<FishGroupsOutletContext>();

  return (
    <Navigate
      to={`/project/${project.id}/fish-groups/${fishGroup.id}/${fishGroupTabs[0].slug}`}
      replace={true}
    />
  );
};

export default FishGroupsId;
