import { HStack, Text } from '@chakra-ui/react';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import Select from 'react-select';

export type ControllerInputs = {
  sublocationOptions: { label: string; value: string }[];
};

const Controller = ({
  controllerSettings,
  chartSettings,
  setChartSettings,
  chartInputs: { sublocationOptions } = { sublocationOptions: [] }
}: ChartController<ChartSettings, ControllerInputs>) => {
  if (controllerSettings?.showSublocations) {
    return (
      <HStack alignItems="center" justifyContent="center">
        <Text>Sublocation: </Text>
        <Select
          className="w-20 tl"
          value={
            chartSettings.selectedSublocation
              ? sublocationOptions.find((lo) => lo.value === chartSettings.selectedSublocation)
              : { label: 'All', value: 'All' }
          }
          onChange={(x) => setChartSettings({ ...chartSettings, selectedSublocation: x.value })}
          options={sublocationOptions}
        />
      </HStack>
    );
  } else {
    return <></>;
  }
};

export default Controller;
