import { add } from 'date-fns';
import { PlotHoverEvent, PlotlyDataLayoutConfig } from 'plotly.js';
import { useEffect, useState } from 'react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import useTideTrace from '../Tides/useTideTrace';
import addOxygenSatShapes from '../addOxygenSatShapes';
import mergePlots from '../mergePlots';
import { BaseChartProps, BaseChartSettings } from '../types';
import useConditionPlots from './useConditionPlots';
import { Site } from 'graphql/generated';
import FishLoader from 'components/Loaders/FishLoader';

export type ChartSettings = BaseChartSettings & {
  onHover?: (e: PlotHoverEvent) => void;
  measures?: (
    | 'waterTempAvg'
    | 'oxygenSaturationAvg'
    | 'oxygenConcentrationAvg'
    | 'salinityAvg'
    | 'measuredDepthAvg'
  )[];
  height?: number;
  useHires?: boolean;
  site?: Site;
};

const MAX_DATE_PADDING = { hours: 6 };

const createTidalAnnotations = (plots: PlotlyDataLayoutConfig[]) => {
  const annotations = [];

  let tidalIdx = 1;
  plots.forEach((p) => {
    const plotData = p.data[0];
    if (plotData.name === 'Forecasted') {
      //@ts-ignore
      annotations.push({
        //@ts-ignore
        x: 1.01,
        y: 1 - tidalIdx / (plots.length / 2),
        yref: 'paper',
        xref: 'paper',
        yanchor: 'middle',
        text: 'Tide (m)',
        showarrow: false,
        font: {
          size: 14
        },
        textangle: 90,
        xshift: 25,
        yshift: 120
      });
      tidalIdx++;
    }
  });

  return annotations;
};

const Chart = ({
  skip,
  settings,
  dateRange,
  chartRange,
  granularity,
  refreshInterval,
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const skipTides = settings.site?.smbId ? false : true;

  const { isLoading, error, plots } = useConditionPlots({
    dateRange,
    chartRange,
    granularity,
    refreshInterval,
    skip,
    onDataLoaded,
    settings
  });

  const {
    isLoading: tidesLoading,
    error: tidesError,
    plot: tidePlot
  } = useTideTrace({
    granularity,
    chartRange,
    dateRange: dateRange ?? 'from 7 days ago to 2 days from now',
    skip: skipTides,
    settings: {
      showNow: false,
      ...settings
    }
  });

  const [fullPlot, setFullPlot] = useState<PlotlyDataLayoutConfig>({ data: [] });

  useEffect(() => {
    if (
      isLoading ||
      error ||
      !plots?.inPenPlots ||
      Object.values(plots?.inPenPlots).some((p) => p.data == null) ||
      tidesLoading ||
      tidesError ||
      (tidePlot?.data == null && !skipTides)
    ) {
      return;
    }

    const displayedPlots = [
      plots?.inPenPlots.oxygenSat,
      plots?.inPenPlots.oxygenConcentration,
      plots?.inPenPlots.waterTemp,
      plots?.inPenPlots.depth,
      plots?.inPenPlots.salinity
    ].filter((g) => g);
    const coreLayout = {
      grid: {
        rows: displayedPlots.length,
        columns: 1
      },
      hovermode: 'closest',
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: 1.1
      },
      height:
        settings?.height ?? settings.site?.smbId
          ? 300 * displayedPlots.length
          : 400 * displayedPlots.length,
      margin: { t: 80, b: 60, l: 60, r: 60 },
      xaxis: {
        range:
          Array.isArray(dateRange) ? dateRange 
            : (!isNaN(plots?.minDate?.getTime()) && !isNaN(plots?.maxDate?.getTime()))
            ? [plots.minDate.toISOString(), add(plots.maxDate, MAX_DATE_PADDING).toISOString()]
            : undefined,
        showspikes: true,
        spikemode: 'across',
        spikecolor: 'black',
        hoverformat: '%Y-%m-%d %H:%M'
      },
      autosize: true
    };

    //@ts-ignore
    const plot = {
      //@ts-ignore
      ...mergePlots(coreLayout, displayedPlots, {
        basePlot: tidePlot ?? null, //{ data: [], layout: [] },
        createAnnotationsForPlots: createTidalAnnotations
      })
    };
    if (plots?.inPenPlots.oxygenSat && plots?.inPenPlots.oxygenSat?.data?.length > 0) {
      addOxygenSatShapes(
        skipTides ? 'y' : `y${displayedPlots.length + 1}`,
        [plots.minDate, add(plots.maxDate, MAX_DATE_PADDING)],
        //@ts-ignore
        plot,
        settings.project
      );
    }

    //@ts-ignore
    setFullPlot(plot);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settings.project.thresholds.oxygen_saturation,
    tidePlot,
    tidesError,
    tidesLoading,
    plots?.inPenPlots
  ]);

  if (tidesLoading || isLoading) {
    return <FishLoader minH="600px" height="100%" width="100%" />;
    // return <Skeleton minH="600" height="100%" width="100%" />;
  }

  if (tidesError || error) {
    return <GraphError minH="600px" />;
  }

  return fullPlot?.data?.length ? (
    <Plot
      className="w-100 site-conditions-plot"
      onHover={settings?.onHover}
      useResizeHandler={true}
      {...fullPlot}
    />
  ) : fullPlot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="600px" />
  );
};

export default Chart;
