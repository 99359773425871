import { ChevronDownIcon, ChevronUpIcon, CopyIcon, EditIcon } from '@chakra-ui/icons';
import {
  Badge,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { ProjectReport } from 'graphql/generated';
import { useState } from 'react';

interface ReportTableProps {
  groupedReports: [string, Partial<ProjectReport>[]][];
  onRowClick: (report: Partial<ProjectReport>) => void;
  onEditClick: (report: Partial<ProjectReport>) => void;
  onCloneClick: (report: Partial<ProjectReport>) => void;
}

const ReportTable = ({
  groupedReports,
  onRowClick,
  onEditClick,
  onCloneClick
}: ReportTableProps) => {
  const [expandedReportRow, setExpandedReportRow] = useState<number | null>();

  return (
    <TableContainer w="100%">
      <Table layout="fixed" variant="striped">
        <Thead>
          <Tr>
            <Th w="50px"></Th>
            <Th w="100px">Status</Th>
            <Th w="100px">Type</Th>
            <Th>Name</Th>
            <Th>Created By</Th>
            <Th>Created</Th>
            <Th>Published</Th>
            <Th w="100px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {groupedReports.flatMap(([reportId, reports]) =>
            (expandedReportRow === Number(reportId) ? reports : [reports[0]]).map(
              //@ts-ignore
              (report: Partial<ProjectReport>, i: number) => (
                <Tr
                  data-cypress="report-row"
                  onClick={() => {
                    onRowClick(report as Partial<ProjectReport>);
                  }}
                  key={report.id}
                  _hover={{ cursor: 'pointer' }}>
                  <Td
                    style={
                      i !== 0
                        ? {
                            borderLeft: '10px solid',
                            borderLeftColor: 'var(--chakra-colors-blue-500)'
                          }
                        : {}
                    }>
                    {reports?.length > 1 && i === 0 && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if ((report?.parentProjectReportId ?? report.id) === expandedReportRow)
                            setExpandedReportRow(null);
                          else setExpandedReportRow(report?.parentProjectReportId ?? report.id);
                        }}
                        variant="ghost"
                        icon={
                          (report?.parentProjectReportId ?? report.id) === expandedReportRow ? (
                            <ChevronUpIcon />
                          ) : (
                            <ChevronDownIcon />
                          )
                        }
                        aria-label={'Expand Report Row'}
                      />
                    )}
                  </Td>
                  <Td>
                    <Badge colorScheme={report.published ? 'green' : 'gray'}>
                      {report.published ? 'Published' : 'Draft'}
                    </Badge>
                  </Td>
                  <Td>
                    {!report.type ? (
                      '-'
                    ) : (
                      <Badge variant="outline" colorScheme="blue">
                        {report.type}
                      </Badge>
                    )}
                  </Td>
                  <Td overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                    {report.name ?? '-'}
                  </Td>
                  <Td>{report.createdBy.name}</Td>
                  <Td>{format(new Date(report.created), 'PP p')}</Td>
                  <Td>{report?.published ? format(new Date(report?.published), 'PP p') : ''}</Td>
                  <Td textAlign="center">
                    {!report.published ? (
                      <IconButton
                        onClick={() => onEditClick(report)}
                        colorScheme="blue"
                        icon={<EditIcon />}
                        aria-label={'Edit Report'}
                      />
                    ) : (
                      <IconButton
                        onClick={async (e) => {
                          e.stopPropagation();
                          onCloneClick(report);
                        }}
                        variant="outline"
                        colorScheme="green"
                        icon={<CopyIcon />}
                        aria-label={'Clone Report'}
                      />
                    )}
                  </Td>
                </Tr>
              )
            )
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
