import { gql, useMutation, useQuery } from '@apollo/client';
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Center,
  Heading,
  HStack,
  Skeleton,
  VStack,
  Wrap,
  Text,
  Box,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  useCheckboxGroup
} from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import CheckboxCard from 'components/Forms/CheckboxCard';
import FormInput from 'components/Forms/FormInput';
import FormNumberInput from 'components/Forms/FormNumberInput';
import FormSelect from 'components/Forms/FormSelect';
import InfoPopover from 'components/InfoPopover';
import { tabArray } from 'components/Site/Tabs/tabs';
import Tile from 'components/Tile';
import { HeadlineDataContextProvider } from 'contexts/HeadlineDataContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { QueryContextProvider } from 'contexts/QueryContext';
import { UserContext } from 'contexts/UserContext';
import { Site } from 'graphql/generated';
import { GET_SITE } from 'graphql/globalQueries';
import useHeadlineStats, { HeadlineStatName } from 'hooks/useHeadlineStats';
import { sortBy, startCase, uniq } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, Link, useParams } from 'react-router-dom';

const UPDATE_SITE = gql`
  mutation UpdateSite($siteId: Int!, $siteInput: SiteInput!) {
    updateSite(siteId: $siteId, siteInput: $siteInput) {
      id
    }
  }
`;

const BATCH_UPDATE_SITES = gql`
  mutation BatchUpdateSites($siteIds: [Int!]!, $siteInput: SiteInput!) {
    batchUpdateSites(siteIds: $siteIds, siteInput: $siteInput)
  }
`;

const DropZone = ({ onDrop }: { onDrop?: () => void }) => {
  const [highlighted, setHighlighted] = useState<boolean>(false);

  return (
    <Center
      onDragEnter={() => setHighlighted(true)}
      onDragExit={() => setHighlighted(false)}
      onDragEnd={() => setHighlighted(false)}
      onDragLeave={() => setHighlighted(false)}
      onDrop={() => {
        setHighlighted(false);
        if (onDrop) {
          onDrop();
        }
      }}
      w="80px"
      h="95px"
      border={`1px ${highlighted ? 'solid' : 'dashed'}`}
      borderColor={highlighted ? 'green.500' : 'blue.200'}
      borderRadius={'2px'}>
      <Text zIndex={-1} color="gray.400">
        Drop Here
      </Text>
    </Center>
  );
};

type SiteForm = {
  name: string;
  latitude: number;
  longitude: number;
  sampleLocations: string[];
  region: string;
  siteLabel: 'ASC' | 'Farm' | 'Pilot' | 'Seed' | 'Sensor';
  tabs: string[];
  archived: boolean;
  includedRiverStations: string[];
  excludedRiverStations: string[];
  includedTideStations: string[];
  excludedTideStations: string[];
};

const SITE_TYPES = [
  { label: 'Pilot', value: 'Pilot' },
  { label: 'ASC', value: 'ASC' },
  { label: 'Farm', value: 'Farm' },
  { label: 'Sensor', value: 'Sensor' },
  { label: 'Seed Area', value: 'Seed Area' }
];

const SiteIdEdit = () => {
  const user = useContext(UserContext);
  const project = useContext(ProjectContext);
  const toast = useToast();

  const { siteId } = useParams();
  const { data, loading } = useQuery<{ site: Site }>(GET_SITE, {
    skip: !siteId,
    variables: {
      id: Number(siteId)
    }
  });

  const { control, watch, reset } = useForm<SiteForm>();

  const formSiteLabel = watch('siteLabel');
  const formTabsPanes = watch('tabs');
  const formSampleLocations = watch('sampleLocations');

  const panes = tabArray.flatMap(
    (ta) =>
      ta?.panes?.map((p) => ({
        displayName: `${ta.displayName} ${p.displayName}`,
        serverKey: p.serverKey
      })) ?? []
  );
  const siteTabsAndPanesOptions = [...tabArray, ...panes].map((ta) => ({
    label: ta.displayName,
    value: ta.serverKey
  }));

  const sampleLocationOptions = data?.site.sublocations.map((s) => ({ label: s, value: s }));

  useEffect(() => {
    if (!data?.site) return;
    reset({
      ...data.site,
      siteLabel: data.site.siteLabel as SiteForm['siteLabel'],
      longitude: data.site.lon,
      latitude: data.site.lat
    });
  }, [data]);

  const [updateSite, { loading: isUpdatingSite }] = useMutation(UPDATE_SITE, {
    refetchQueries: ['siteById']
  });

  const [batchUpdateSites, { loading: isBatchUpdatingSites }] = useMutation(BATCH_UPDATE_SITES, {
    refetchQueries: ['siteById']
  });

  const allStats = useHeadlineStats({ statNames: 'all' });

  const [headlineModalOpen, setHeadlineModalOpen] = useState<boolean>(false);

  const [applyToSites, setApplyToSites] = useState<number[]>([data?.site.id]);

  const [verifyHeadlineAssign, setVerifyHeadlineAssign] = useState<boolean>(false);

  const { getCheckboxProps } = useCheckboxGroup({
    value: applyToSites,
    onChange: (ids) => setApplyToSites(ids.map((i) => Number(i)))
  });

  const [assignedStats, setAssignedStats] = useState<{ key: HeadlineStatName; order: number }[]>(
    []
  );

  const [draggingStat, setDraggingStat] = useState<HeadlineStatName | null>();

  const assignedStatComponents = useHeadlineStats({
    statNames: assignedStats.map((assigned) => assigned.key)
  });

  useEffect(() => {
    if (!data?.site || loading) return;
    //@ts-ignore
    setAssignedStats(sortBy(data.site.headlineStats, 'order'));
  }, [data]);

  const availableStats = useMemo(() => {
    const assignedKeys = assignedStats.map((assigned) => assigned.key);
    return sortBy(
      allStats.filter((allStat) => !assignedKeys.includes(allStat.statName)),
      'statName'
    );
  }, [allStats, assignedStats]);

  const onDropAssignStat = useCallback(
    (index: number) => {
      let newStats = [...assignedStats];
      if (assignedStats.map((assigned) => assigned.key).includes(draggingStat)) {
        newStats = newStats.filter((ns) => ns.key !== draggingStat);
      }
      newStats.splice(index, 0, { key: draggingStat, order: index });
      newStats = newStats.map((s, i) => ({ ...s, order: i }));
      setAssignedStats(newStats);
      setDraggingStat(null);
    },
    [draggingStat, setAssignedStats, assignedStats]
  );

  const save = async () => {
    try {
      await updateSite({
        variables: {
          siteId: Number(siteId),
          siteInput: {
            headlineStats: assignedStats.map((assigned) => ({
              key: assigned.key,
              order: assigned.order
            }))
          }
        }
      });
      toast({
        status: 'success',
        description: 'Successfully saved site.'
      });
    } catch {
      toast({
        status: 'error',
        description: 'Error saving site.'
      });
    }
  };

  if (!permissions.canAdminProject(user, project.id)) {
    return <Navigate to={`/project/${project.id}/site/${siteId}`} />;
  }

  if (loading || !data?.site) return <Skeleton w="100%" h="800px" />;

  return (
    <Center mt="20px" w="100%">
      <VStack w="100%">
        <Box alignSelf="start">
          <Link to={`/project/${project.id}/edit`}>
            <ChevronLeftIcon display="inline" />
            <Text display="inline">Back to project management</Text>
          </Link>
        </Box>
        <HStack>
          <Heading fontSize="2xl">Manage {data.site.name}</Heading>
          <Link to={`/project/${project.id}/site/${data.site.id}`}>
            <ExternalLinkIcon mx="2px" />
          </Link>
        </HStack>

        <Tile w="100%" p="10px">
          <Heading fontSize="md" my="20px">
            General Configuration
          </Heading>

          <VStack>
            <FormInput step={0.000001} control={control} name="name" label="Name" />
            <FormNumberInput control={control} name="latitude" label="Latitude" />
            <FormNumberInput control={control} name="longitude" label="Longitude" />
            <FormSelect
              isMulti={true}
              control={control}
              name="sampleLocations"
              label="Lice & Plankton Sample Locations"
              options={sampleLocationOptions}
              value={sampleLocationOptions.filter((sl) => formSampleLocations?.includes(sl.value))}
            />
            <FormInput label="Region" control={control} name="region" />
            <FormSelect
              label="Site Label / Type"
              control={control}
              options={SITE_TYPES}
              value={SITE_TYPES.find((t) => t.value === formSiteLabel)}
              name="siteLabel"
            />
            <FormSelect
              isMulti={true}
              label="Tabs & Panes"
              control={control}
              name="tabs"
              options={siteTabsAndPanesOptions}
              value={siteTabsAndPanesOptions.filter((tpo) => formTabsPanes?.includes(tpo.value))}
            />
            <FormSelect
              control={control}
              isMulti={true}
              name="includedTideStations"
              label="Include Tide Stations"
            />
            <FormSelect
              control={control}
              isMulti={true}
              name="excludedTideStations"
              label="Exclude Tide Stations"
            />
            <FormSelect
              control={control}
              isMulti={true}
              name="includedRiverStations"
              label="Include River Stations"
            />
            <FormSelect
              control={control}
              isMulti={true}
              name="excludedRiverStations"
              label="Exclude River Stations"
            />
          </VStack>
        </Tile>

        <Tile w="100%" p="10px">
          <Heading fontSize="md" my="20px">
            Headline Tiles
            <InfoPopover pb="2px" ml="5px">
              <Text fontWeight="normal">
                Customize your sites headlines by clicking, dragging, and re-ordering to fit your
                needs.
              </Text>
            </InfoPopover>
          </Heading>
          <Button
            onClick={() => setHeadlineModalOpen(true)}
            float="right"
            rightIcon={<ChevronRightIcon />}>
            Apply to More Sites
          </Button>
          <QueryContextProvider site={data.site}>
            <HeadlineDataContextProvider
              site={data.site}
              project={project}
              requestedDatasets={uniq(allStats.flatMap((s) => s.requiredDatasets))}>
              <HStack w="100%" alignItems="start">
                <Box
                  minW="280px"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    setAssignedStats(
                      assignedStats.filter((assigned) => assigned.key !== draggingStat)
                    );
                    setDraggingStat(null);
                  }}>
                  <Text textAlign="center">Unassigned Tiles</Text>
                  <VStack
                    h="600px"
                    overflowY="scroll"
                    textAlign="center"
                    p="5px"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="3px">
                    {availableStats.map((StatComponent, index) => (
                      <Box
                        draggable
                        key={index}
                        onDragStart={() => setDraggingStat(StatComponent.statName)}>
                        <StatComponent
                          m={0}
                          href={undefined}
                          clickable={false}
                          project={project}
                          site={data.site}
                        />
                      </Box>
                    ))}
                  </VStack>
                </Box>
                <Box w="100%" onDragOver={(e) => e.preventDefault()}>
                  <Text textAlign="center">Assigned Tiles</Text>
                  <Wrap
                    h="600px"
                    overflowY="scroll"
                    p="5px"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="3px"
                    w="100%">
                    {assignedStatComponents.map((StatComponent, index) => (
                      <HStack onDrop={() => onDropAssignStat(index)} key={index}>
                        <DropZone />
                        <Box
                          draggable
                          key={`stat-${index}`}
                          onDragStart={() => setDraggingStat(StatComponent.statName)}>
                          <StatComponent
                            m={0}
                            href={undefined}
                            clickable={false}
                            project={project}
                            site={data.site}
                          />
                        </Box>
                      </HStack>
                    ))}
                    <DropZone onDrop={() => onDropAssignStat(assignedStatComponents.length)} />
                  </Wrap>
                </Box>
              </HStack>
            </HeadlineDataContextProvider>
          </QueryContextProvider>
        </Tile>
        <Button
          isDisabled={!data?.site}
          isLoading={isUpdatingSite}
          onClick={() => save()}
          alignSelf="end"
          leftIcon={<CheckIcon />}
          colorScheme="blue">
          Save Site
        </Button>
      </VStack>

      {headlineModalOpen && (
        <Modal
          onClose={() => {
            setHeadlineModalOpen(false);
            setVerifyHeadlineAssign(false);
            setApplyToSites([data?.site.id]);
          }}
          isOpen={headlineModalOpen}
          size="2xl">
          <ModalOverlay />
          <ModalContent mt="100px">
            <ModalHeader>Assign Headline</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ButtonGroup mb="20px" isAttached={true}>
                {uniq(project.sites.map((s) => startCase(s.type))).map((siteType) => (
                  <Button
                    key={siteType}
                    onClick={() => {
                      setApplyToSites(
                        project.sites
                          .filter((s) => s.type.toLowerCase() === siteType.toLowerCase())
                          .map((s) => s.id)
                      );
                    }}>
                    All {siteType}
                  </Button>
                ))}
              </ButtonGroup>

              <VStack pb="20px" w="100%" maxH="600px" overflowY="scroll">
                {sortBy(project.sites, 'name').map((s) => {
                  const checkbox = getCheckboxProps({ value: s.id });
                  return (
                    <CheckboxCard boxProps={{ w: '100%' }} key={s.id} {...checkbox}>
                      <Text display="inline-block" data-cypress={`select-site-${s.id}`}>
                        {s.name}
                      </Text>
                      <Text display="inline-block" float="right">
                        {startCase(s.type)}
                      </Text>
                    </CheckboxCard>
                  );
                })}
              </VStack>
            </ModalBody>
            <ModalFooter>
              {verifyHeadlineAssign && (
                <Text color="red.500">
                  Notice! This will override any configurations already present at these sites. This
                  action cannot be undone!
                </Text>
              )}
              <Button
                isLoading={isBatchUpdatingSites}
                w="220px"
                leftIcon={<CheckIcon />}
                variant={!verifyHeadlineAssign ? 'outline' : 'solid'}
                onClick={async () => {
                  if (!verifyHeadlineAssign) {
                    setVerifyHeadlineAssign(true);
                    return;
                  } else {
                    try {
                      await batchUpdateSites({
                        variables: {
                          siteIds: applyToSites,
                          siteInput: {
                            headlineStats: assignedStats.map((assigned) => ({
                              key: assigned.key,
                              order: assigned.order
                            }))
                          }
                        }
                      });
                      setHeadlineModalOpen(false);
                      setVerifyHeadlineAssign(false);
                      setApplyToSites([data?.site.id]);
                      toast({
                        status: 'success',
                        description: 'Successfully saved site.'
                      });
                    } catch {
                      toast({
                        status: 'error',
                        description: 'Error applying headlines to multiple sites.'
                      });
                    }
                  }
                }}
                colorScheme="blue">
                {!verifyHeadlineAssign ? 'Apply' : 'Confirm'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Center>
  );
};

export default SiteIdEdit;
