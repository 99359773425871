import { Skeleton, Spacer } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { QueryContext } from 'contexts/QueryContext';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, sum, uniq } from 'lodash';
import { PlotData, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext, useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

type MortalityDatum = {
  'TessMortalityLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessMortality.measuredAt': string;
  'TessMortalityLookup.mortalityCauseGroupName': string;
  'TessMortalityLookup.mortalityCauseName': string;
  'TessMortality.mortalityCountSum': number;
  'TessMortality.mortalityBiomassKgSum': number;
};

type MortalityStructure = {
  mortalityCauseGroupedData: {
    [mortalityCause: string]: {
      measuredAt: string;
      count: number;
      biomass: number;
      percent: number;
      causeGroup: string;
      causeName: string;
      sublocation: string;
    }[];
  };
  totalMortalityData: {
    [day: string]: number;
  };
};

export type ChartSettings = BaseChartSettings & {
  unit: 'count' | 'biomass' | 'percent';
  showByCauseName?: boolean;
  selectedSublocation?: string;
};

export type ChartInputs = {
  sublocationOptions?: { value: string; label: string }[];
};

const Chart = ({
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  skip,
  settings,
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ChartInputs>) => {
  const {
    contextFiltersFor,
    contextDimensionsFor,
    datumKey,
    datumKeyToTraceName,
    contextDateRange
  } = useContext(QueryContext);
  const dimensions = contextDimensionsFor({ cube: 'TessMortality' });

  const transform = (data: MortalityDatum[]): MortalityStructure => {
    let filteredDataSublocation = data;
    if (settings?.selectedSublocation && settings?.selectedSublocation != 'All') {
      // filter to sublocation
      filteredDataSublocation = filteredDataSublocation.filter(
        (d) => datumKey(d, dimensions) === settings.selectedSublocation
      );
    }

    const grouped = groupBy(
      filteredDataSublocation,
      settings.showByCauseName
        ? 'TessMortalityLookup.mortalityCauseName'
        : 'TessMortalityLookup.mortalityCauseGroupName'
    );

    const totalMortalityData = filteredDataSublocation.reduce(
      (acc, curr) => {
        const daySum = acc[curr[`TessMortality.measuredAt.${granularity}`]];
        acc[curr[`TessMortality.measuredAt.${granularity}`]] = daySum
          ? daySum + curr['TessMortality.mortalityCountSum']
          : curr['TessMortality.mortalityCountSum'];

        return acc;
      },
      {} as { [day: string]: number }
    );

    const mortalityCauseGroupedData = Object.keys(grouped).reduce((causeAcc, cause: string) => {
      causeAcc[cause] = grouped[cause].map((d) => ({
        measuredAt: d[`TessMortality.measuredAt.${granularity}`],
        count: Number(d['TessMortality.mortalityCountSum']),
        biomass: Number(d['TessMortality.mortalityBiomassKgSum']),
        causeGroup: d['TessMortalityLookup.mortalityCauseGroupName'],
        causeName: d['TessMortalityLookup.mortalityCauseName'],
        sublocation: datumKey(d, dimensions)
      }));

      return causeAcc;
    }, {});

    return {
      mortalityCauseGroupedData,
      totalMortalityData
    };
  };

  const graph = (data: MortalityStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const causes = uniq(Object.keys(data.mortalityCauseGroupedData)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );

    const causePallet = settings?.showByCauseName ? allMortalityCauses : allMortalityCauseGroups;
    const pallet = createLocationPallet({ locations: causePallet, palletType: 'mortalityCauses' });
    const causeLegend = {};
    //@ts-ignore
    const plotData: Partial<PlotData>[] = causes.map((cause) => {
      const causeData = data.mortalityCauseGroupedData[cause];
      const timeGrouped = groupBy(causeData, 'measuredAt');
      const timeAggregatedData = Object.values(timeGrouped).map((timeGroupedValues) => {
        const countSum = sum(timeGroupedValues.map((v) => v.count));

        return {
          measuredAt: timeGroupedValues[0].measuredAt,
          count: countSum,
          biomass: sum(timeGroupedValues.map((v) => v.biomass)).toFixed(1),
          percent: (
            (countSum / data.totalMortalityData[timeGroupedValues[0].measuredAt]) *
            100
          ).toFixed(2)
        };
      });

      // Sublocations for cause
      const uniqueSublocations = uniq(causeData.map((d) => d.sublocation)).sort(
        (a, b) => locationToIndex(a) - locationToIndex(b)
      );

      causeLegend[cause] = true;

      return {
        type: 'bar',
        name: cause,
        x: timeAggregatedData.map((d) => d.measuredAt),
        y: timeAggregatedData.map((d) =>
          settings.unit === 'biomass' ? d.biomass : settings.unit === 'count' ? d.count : d.percent
        ),
        marker: {
          color: pallet[cause]
        },
        legendgroup: cause,
        showlegend: causeLegend[cause],
        hovertemplate: `<b>%{x}</b><br>Cause: <b>%{text.cause}</b><br><br>%{text.sublocations}<br><br>${
          dependencies?.biomassToggle ? 'Total: <b>%{y:.1f}</b>' : 'Total: <b>%{y:0.f}</b>'
        }<extra></extra>`,
        hoverlabel: {
          align: 'left'
        },
        text: timeAggregatedData.map((d) => {
          const sublocationDetails = uniqueSublocations
            .map((sublocation) => {
              const sublocationData = causeData.filter(
                (cd) => cd.sublocation === sublocation && cd.measuredAt === d.measuredAt
              );
              if (sublocationData.length === 0) return null;
              const displayName = datumKeyToTraceName(sublocation);

              const sublocationSum = sum(sublocationData?.map((d) => d.count));

              return `${displayName}: <b>${
                settings.unit == 'biomass'
                  ? sum(sublocationData?.map((d) => d.biomass)).toFixed(1)
                  : settings.unit === 'count'
                    ? sublocationSum
                    : ((sublocationSum / data.totalMortalityData[d.measuredAt]) * 100).toFixed(2)
              }</b>`;
            })
            .filter((s) => s)
            .join('<br>');

          return {
            value: dependencies?.biomassToggle ? d.biomass : d.count,
            sublocations: sublocationDetails,
            cause
          };
        })
      };
    });

    const title = settings?.site
      ? `Mortality By Cause - ${settings.site.name}`
      : `Mortality By Cause - All Sites`;

    const measurementLabel =
      settings.unit === 'biomass'
        ? 'Weight (kg)'
        : settings.unit === 'count'
          ? 'Count'
          : 'Percent (%)';
    const causeLabel = settings.showByCauseName ? 'Cause' : 'Cause Group';

    const yLabel = `${measurementLabel} By ${causeLabel}`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      barmode: 'stack',
      yaxis: {
        title: {
          text: yLabel
        }
      },
      xaxis: {
        title: `${minDate} - ${maxDate} by ${granularity}`,
        range: chartRange
      },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.05 + Math.floor(causes.length / 5) * 0.04,
        tracegroupgap: 0
      },
      margin: {
        t: 25
      },
      hovermode: 'closest'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  // Distinct Mortality-Causes for Project-Wide Color Mappings
  const mortalityCauseQuery = useCubeQuery(
    {
      dimensions: [
        'TessMortalityLookup.mortalityCauseGroupName',
        'TessMortalityLookup.mortalityCauseName'
      ]
    },
    {
      skip: false,
      subscribe: false
    }
  );
  const allMortalityCauseGroups: string[] = uniq(
    mortalityCauseQuery.resultSet
      ?.rawData()
      .map((d) => String(d['TessMortalityLookup.mortalityCauseGroupName']))
  ).sort((a, b) => locationToIndex(a) - locationToIndex(b));
  const allMortalityCauses: string[] = uniq(
    mortalityCauseQuery.resultSet
      ?.rawData()
      .map((d) => String(d['TessMortalityLookup.mortalityCauseName']))
  ).sort((a, b) => locationToIndex(a) - locationToIndex(b));

  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      measures: ['TessMortality.mortalityCountSum', 'TessMortality.mortalityBiomassKgSum'],
      timeDimensions: [
        {
          dimension: 'TessMortality.measuredAt',
          granularity,
          dateRange: contextDateRange(dateRange)
        }
      ],
      dimensions: [
        ...contextDimensionsFor({ cube: 'TessMortality' }),
        'TessMortalityLookup.mortalityCauseGroupName',
        'TessMortalityLookup.mortalityCauseName'
      ],
      filters: [
        ...contextFiltersFor({ cube: 'TessMortality' }),
        { member: 'TessMortality.mortalityCountSum', operator: 'gt', values: ['0'] }
      ],
      order: { 'TessMortality.measuredAt': 'asc' },
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: {
        unit: settings.unit,
        causeGroupToggle: settings.showByCauseName,
        selectedSublocation: settings.selectedSublocation,
        chartRange
      },
      onDataLoaded
    }
  });

  const sublocations = useMemo(() => {
    return uniq(Object.keys(groupBy(resultSet?.rawData(), (d) => datumKey(d, dimensions)))).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 ? (
    <NoData minH="450px" />
  ) : (
    <>
      {control ? (
        control({
          sublocationOptions: [
            { label: 'All', value: 'All' },
            ...sublocations.map((s) => ({ label: datumKeyToTraceName(s), value: s }))
          ]
        })
      ) : (
        <></>
      )}
      <Spacer mt="20px" />
      <Plot className="w-100" useResizeHandler={true} {...plot} />
    </>
  );
};

export default Chart;
