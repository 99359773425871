import { Skeleton } from '@chakra-ui/react';
import { QueryContext } from 'contexts/QueryContext';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

type FeedUseDatum = {
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
  'Biology.feedUseWeight': number;
  'Biology.appetite': number;
};

type FeedUseStructure = {
  [sublocation: string]: {
    feeduse: number;
    appetite: number;
    measuredAt: string;
  }[];
};

export type ChartSettings = BaseChartSettings & {
  useTotal: boolean;
  useAppetite: boolean;
};

const Chart = ({
  granularity = 'day',
  dateRange = 'Last 30 days',
  chartRange,
  skip,
  settings,
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const {
    contextFiltersFor,
    contextDimensionsFor,
    datumKey,
    datumKeyToTraceName,
    contextDateRange
  } = useContext(QueryContext);
  const dimensions = contextDimensionsFor({ cube: 'Biology' });

  const transform = (data: FeedUseDatum[]): FeedUseStructure => {
    const bySublocation = groupBy(data, (d) => datumKey(d, dimensions));
    const output = Object.keys(bySublocation).reduce((acc, sublocation: string) => {
      const byMeasuredAt = bySublocation[sublocation].map((datum) => ({
        feeduse: datum['Biology.feedUseWeight'],
        appetite: datum['Biology.appetite'],
        measuredAt: datum[`Biology.measuredAt.${granularity}`]
      }));
      acc[sublocation] = byMeasuredAt;
      return acc;
    }, {});

    // Calc the site average
    const byMeasuredAt = groupBy(data, (d) => d[`Biology.measuredAt.${granularity}`]);
    output['site'] = Object.keys(byMeasuredAt).map((measuredAt: string) => ({
      // Sum Feed Use for Site
      feeduse: byMeasuredAt[measuredAt].reduce(
        (subAcc, datum) => subAcc + datum['Biology.feedUseWeight'],
        0
      ),
      // Average Appetite for Site
      appetite:
        byMeasuredAt[measuredAt].reduce((subAcc, datum) => subAcc + datum['Biology.appetite'], 0) /
        byMeasuredAt[measuredAt].length,
      measuredAt
    }));

    return output;
  };

  const graph = (data: FeedUseStructure): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data))
      .sort((a, b) => locationToIndex(a) - locationToIndex(b))
      .filter((subloc) => {
        return settings.useTotal ? subloc.includes('site') : !subloc.includes('site');
      });
    const pallet = createLocationPallet({ locations: sublocations });

    const plotData: Data[] = sublocations.map((subloc) => {
      const name = datumKeyToTraceName(subloc);
      return {
        type: 'scatter',
        mode: 'lines+markers',
        name: subloc == 'site' ? 'Total' : name,
        x: data[subloc].map((d) => d.measuredAt),
        y: Object.values(data[subloc]).flatMap((d) =>
          settings.useAppetite ? d.appetite : d.feeduse
        ),
        marker: {
          color: pallet[subloc]
        },
        line: {
          dash: subloc.includes('site') ? 'dash' : 'solid',
          width: subloc.includes('site') ? 3 : 2
        },
        showlegend: true,
        hovertemplate: settings.useAppetite
          ? '<b>%{x}<br>%{y:0.2f}</b>'
          : '<b>%{x}<br>%{y:0.0f}</b>'
      };
    });

    const locationLabel = settings?.site ? 'Cage' : 'Site';

    const typeLabel = settings.useAppetite ? 'Appetite (%)' : 'Feed Use (kg)';

    const measurementLabel = settings.useTotal
      ? `${typeLabel} Total`
      : `${typeLabel} By ${locationLabel}`;

    const title = settings?.site
      ? `${typeLabel} - ${settings.site.name}`
      : `${typeLabel} - All Sites`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25
      },
      yaxis: {
        title: {
          text: measurementLabel,
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        title: `${minDate} - ${maxDate} by ${granularity}`,
        range: chartRange
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.feedUseWeight', 'Biology.appetite'],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange: contextDateRange(dateRange)
        }
      ],
      dimensions: contextDimensionsFor({ cube: 'Biology' }),
      filters: contextFiltersFor({ cube: 'Biology' }),
      order: { 'Biology.measuredAt': 'asc' },
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: {
        useTotal: settings.useTotal,
        useAppetite: settings.useAppetite,
        chartRange
      },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default Chart;
