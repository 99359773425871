import { useEffect } from 'react';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings, ChartInputs } from './Chart';

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs
}: ChartController<ChartSettings, ChartInputs>) => {

  useEffect(() => {
    if(chartSettings?.selectedSublocation?.value === "" && chartInputs.sublocations?.length > 0) {
      setChartSettings({ ...chartSettings, selectedSublocation: chartInputs.sublocations[0] })
    }
  }, [chartInputs])

  return (
    <ChartSwitchPanel
      selects={[
        {
          label: 'Sublocation ',
          value: chartSettings.selectedSublocation,
          options: chartInputs.sublocations,
          width: '100%',
          maxWidth: '400px',
          minWidth: '150px',
          placeholder: 'Select Sublocation',
          onChange: (x: { label: string; value: string }) =>
            setChartSettings({
              ...chartSettings,
              selectedSublocation: x
            })
        }
      ]}
      switches={[
        {
          switchProps: { isChecked: chartSettings.useBiomass },
          left: { value: 'left', label: 'Fish Count' },
          right: { value: 'right', label: 'Biomass (kg)' },
          toggle: (x) => setChartSettings({ ...chartSettings, useBiomass: x.checked })
        }
      ]}
    />
  );
};

export default Controller;
