import { gql, useMutation, useQuery } from '@apollo/client';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Heading,
  Skeleton,
  Text,
  VStack,
  useToast,
  Kbd,
  HStack,
  Card,
  CardHeader,
  CardBody
} from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { PopulationsTimeline, Z_INDICES } from 'components/PopulationsTimeline';
import { ProjectContext } from 'contexts/ProjectContext';
import { endOfDay, format, sub } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Population } from 'graphql/generated';
import { groupBy, uniq, uniqBy } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { IconType } from 'react-icons';
import { GiFishingNet } from 'react-icons/gi';
import { HiOutlineTruck } from 'react-icons/hi';
import { MdCallSplit, MdMerge } from 'react-icons/md';
import { TbArrowDownRightCircle, TbArrowUpRightCircle, TbCircle } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import Xarrow from 'react-xarrows';

const CREATE_FISH_GROUP_MUTATION = gql`
  mutation CreateFishGroup($fishGroup: FishGroupInput!) {
    createFishGroup(fishGroup: $fishGroup)
  }
`;

const UPDATE_FISH_GROUP_MUTATION = gql`
  mutation UpdateFishGroup($id: Int!, $fishGroup: FishGroupInput!) {
    updateFishGroup(id: $id, fishGroup: $fishGroup) {
      id
      name
      populationIds
    }
  }
`;

const POPULATION_QUERY = gql`
  query PopulationHistoryTimeline($populationsWhere: PopulationWhere!) {
    populations(where: $populationsWhere) {
      SmbId
      PopulationId
      site {
        id
        name
      }
      Container
      InputYear
      StartTime
      EndTime
      Tags
      relatedInputsConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            StartTime
          }
        }
      }
      relatedPopulationsInConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            PopulationId
            Container
            StartTime
            EndTime
            Tags
            site {
              id
              name
            }
          }
        }
      }
      relatedPopulationsOutConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            PopulationId
            Container
            StartTime
            EndTime
            Tags
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_FISH_GROUP_QUERY = gql`
  query GetFishGroup($id: Int!) {
    fishGroup(id: $id) {
      id
      name
      populationIds
    }
  }
`;

export type PopulationEventType =
  | 'stock'
  | 'transfer-in'
  | 'transfer-out'
  | 'split'
  | 'merge'
  | 'harvest'
  | 'other';

const typeToTitle = {
  stock: 'Stock',
  merge: 'Merge',
  split: 'Split',
  'transfer-out': 'Transfer Out',
  'transfer-in': 'Transfer In',
  other: 'Unknown/Other',
  harvest: 'Harvest'
};

type PopulationEvent = {
  id: string;
  populationId: string;
  site: string;
  container: string;
  group: string;
  date: Date;
  count: number;
  biomass: number;
  avgBiomass: number;
  type: PopulationEventType;
  tags?: string;
  fromSite?: string;
  fromContainer?: string;
  fromId?: string;
  toSite?: string;
  toContainer?: string;
  toId?: string;
};

const PopulationEventArrows = ({ events }: { events: PopulationEvent[] }) => {
  const uniqEvents = uniqBy(events, (e) => `${e.id}-${e.toId}`);
  const arrows = uniqEvents.map((populationEvent) => {
    if (populationEvent.toId) {
      return (
        <Xarrow
          dashness={{ animation: true, strokeLen: 10, nonStrokeLen: 5 }}
          strokeWidth={2}
          color="darkslategrey"
          zIndex={Z_INDICES.arrows}
          key={`${populationEvent.id}-${populationEvent.toId}`}
          start={populationEvent.id}
          end={populationEvent.toId}
        />
      );
    } else if (populationEvent.fromId) {
      return (
        <Xarrow
          strokeWidth={3}
          color="chocolate"
          zIndex={Z_INDICES.arrows}
          key={`${populationEvent.fromId}-${populationEvent.id}`}
          start={populationEvent.fromId}
          end={populationEvent.id}
        />
      );
    } else {
      return null;
    }
  });

  return <>{arrows.filter((a) => a)}</>;
};

const SplitMergeCard = ({
  title,
  count,
  biomass,
  avgBiomass,
  from,
  to,
  addt
}: {
  title: string;
  count: number;
  biomass: number;
  avgBiomass?: number;
  from?: string;
  to?: string;
  addt?: string;
}) => {
  return (
    <Card w="200px">
      <CardHeader p="0" m="10px">
        {title}
      </CardHeader>
      <CardBody p="0" mx="10px">
        <VStack alignItems="start" fontSize="xs" spacing="0">
          <Text fontWeight="bold">Count</Text>
          <Text>{count}</Text>
          <Text fontWeight="bold">Total Biomass (kg)</Text>
          <Text>{biomass.toFixed(0)}</Text>
          {avgBiomass && (
            <>
              <Text fontWeight="bold">Avg. Biomass (kg)</Text>
              <Text>{avgBiomass.toFixed(0)}</Text>
            </>
          )}
          {from && (
            <>
              <Text fontWeight="bold">From</Text>
              <Text>{from}</Text>
            </>
          )}
          {to && (
            <>
              <Text fontWeight="bold">To</Text>
              <Text>{to}</Text>
            </>
          )}
          {addt && (
            <>
              <Text fontWeight="bold">(Addt.)</Text>
              <Text>{addt}</Text>
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

const SplitContent = ({ events }: { events: PopulationEvent[] }) => {
  const from = events.filter((e) => e?.fromId !== undefined)[0];
  const endOfDaySplit = events.filter((e) => e.toId === from.id);
  const externalTransfers = events.filter((e) => e.toId && e.toId !== from.fromId);
  endOfDaySplit.sort((e1, e2) => e1.biomass - e2.biomass);

  const stacks = endOfDaySplit.map((m, i) => {
    const relatedTransfer = externalTransfers?.find((t) => t?.populationId === m?.populationId);
    // if (!relatedTransfer) {
    //   console.log('no related split transfers found:');
    //   console.log(events);
    // }
    return (
      <HStack alignItems="stretch" key={i}>
        <SplitMergeCard
          title="Start of Day"
          count={m.count + relatedTransfer.count}
          biomass={m.biomass + relatedTransfer.biomass}
          addt={m?.tags}
        />
        <ArrowForwardIcon />
        <SplitMergeCard
          title="Transferred out"
          count={relatedTransfer?.count ?? 0}
          biomass={relatedTransfer?.biomass ?? 0}
          avgBiomass={relatedTransfer?.avgBiomass}
          to={`${relatedTransfer?.toSite}-${relatedTransfer?.toContainer}`}
          addt={relatedTransfer?.tags}
        />
        <ArrowForwardIcon />
        <SplitMergeCard
          title="Retained After"
          count={m.count}
          biomass={m.biomass}
          avgBiomass={m.avgBiomass}
        />
      </HStack>
    );
  });

  return <VStack spacing={0}>{stacks}</VStack>;
};

const MergeContent = ({ events }: { events: PopulationEvent[] }) => {
  const to = events.filter((e) => e?.toId !== undefined)[0];
  const startOfDayMerged = events.filter((e) => e.fromId === to.id);
  const externalTransfers = events.filter((e) => e.fromId && e.fromId !== to.toId);
  startOfDayMerged.sort((e1, e2) => e1.biomass - e2.biomass);
  const stacks = startOfDayMerged.map((m, i) => {
    const relatedTransfer = externalTransfers?.find((t) => t?.populationId === m?.populationId);
    if (!relatedTransfer) {
      console.warn('Merge Content no related transfer found');
      return <Text key={i}>Hmmm... No related transfer found?</Text>;
    }
    return (
      <HStack alignItems="stretch" key={i}>
        <SplitMergeCard
          title="Start of Day"
          count={m.count}
          biomass={m.biomass}
          avgBiomass={m.avgBiomass}
          addt={m?.tags}
        />
        <ArrowForwardIcon />
        <SplitMergeCard
          title="Transferred In"
          count={relatedTransfer?.count ?? 0}
          biomass={relatedTransfer?.biomass ?? 0}
          avgBiomass={relatedTransfer?.avgBiomass}
          from={`${relatedTransfer?.fromSite}-${relatedTransfer?.fromContainer}`}
          addt={relatedTransfer?.tags}
        />
        <ArrowForwardIcon />
        <SplitMergeCard
          title="After Transfer"
          count={m.count + (relatedTransfer?.count ?? 0)}
          biomass={m.biomass + (relatedTransfer?.biomass ?? 0)}
        />
      </HStack>
    );
  });

  return <VStack spacing={0}>{stacks}</VStack>;
};

const PopulationEventsDetails = ({
  primaryEventType,
  events
}: {
  primaryEventType: PopulationEventType;
  events: PopulationEvent[];
}) => {
  return (
    <VStack w="100%" spacing="0">
      <HStack w="100%" justifyContent="space-evenly">
        <Heading fontSize="sm">{events[0].group}</Heading>
        <Heading fontSize="sm">{typeToTitle[primaryEventType]}</Heading>
        <Heading fontSize="sm">{format(events[0].date, 'PP')}</Heading>
      </HStack>
      {primaryEventType === 'merge' ? (
        <MergeContent events={events} />
      ) : primaryEventType === 'split' ? (
        <SplitContent events={events} />
      ) : (
        <TransferContent events={events} />
      )}
    </VStack>
  );
};

const TransferContent = ({ events }: { events: PopulationEvent[] }) => {
  return (
    <HStack alignItems="stretch">
      {events.map((populationEvent, i) => (
        <SplitMergeCard
          key={i}
          {...populationEvent}
          title={typeToTitle[populationEvent.type]}
          from={
            populationEvent?.fromSite
              ? `${populationEvent?.fromSite}-${populationEvent?.fromContainer}`
              : undefined
          }
          to={
            populationEvent?.toSite
              ? `${populationEvent?.toSite}-${populationEvent?.toContainer}`
              : undefined
          }
          addt={populationEvent?.tags}
        />
      ))}
    </HStack>
  );
};

const FishGroupsEdit = () => {
  const project = useContext(ProjectContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { fishGroupId: idOrNew } = useParams();

  const isNew = useMemo(() => isNaN(Number(idOrNew)), [idOrNew]);

  const date = useMemo(() => new Date(), []);
  const [lookBackMonths, setLookBackMonths] = useState<number>(12);

  const { data: existingFishGroup } = useQuery(GET_FISH_GROUP_QUERY, {
    variables: { id: Number(idOrNew) },
    skip: isNew
  });

  const { data, loading, error } = useQuery<{ populations: Population[] }>(POPULATION_QUERY, {
    variables: {
      populationsWhere: {
        SmbId_IN: project.sites.filter((s) => s.type === 'farm').map((s) => s.smbId),
        StartTime_GTE: sub(date, { months: lookBackMonths })
      }
    }
  });

  const [createFishGroupMutation] = useMutation(CREATE_FISH_GROUP_MUTATION);
  const [updateFishGroupMutation] = useMutation(UPDATE_FISH_GROUP_MUTATION);

  const toProjectTz = (browserDate: Date) =>
    utcToZonedTime(
      zonedTimeToUtc(browserDate, Intl.DateTimeFormat().resolvedOptions().timeZone),
      project.timezone
    );

  const populationEventData = useMemo(
    () =>
      data?.populations?.map((p) => ({
        ...p,
        id: p.PopulationId,
        start: toProjectTz(new Date(p.StartTime)),
        end: p?.EndTime ? toProjectTz(new Date(p.EndTime)) : toProjectTz(new Date()),
        name: p.PopulationId,
        group: `${p.site.name} - ${p.Container}`,
        tags: p.Tags
      })) ?? [],
    [data]
  );

  const transferEvents: PopulationEvent[] = useMemo(() => {
    if (!data?.populations) return [];

    //Bite me
    const toId = (site: string, container: string, date: Date) =>
      `${site}-${container}-${format(endOfDay(date), 'yy-MM-dd')}`.replace(' ', '-');

    const stockEvents = data.populations.flatMap((p) => {
      return p.relatedInputsConnection.edges.map((e) => ({
        id: toId(p.site.name, p.Container, toProjectTz(new Date(p.StartTime))),
        populationId: p.PopulationId,
        site: p.site.name,
        container: p.Container,
        group: `${p.site.name} - ${p.Container}`,
        date: toProjectTz(new Date(p.StartTime)),
        count: e.properties.TransferredCount,
        biomass: e.properties.TransferredBiomassKg,
        avgBiomass: e.properties.TransferredAvgBiomassKg,
        tags: e.properties?.Tags,
        type: 'stock' as const
      }));
    });

    const inputPopulationEvents = data.populations.flatMap((p) => {
      return (
        p.relatedPopulationsInConnection.edges
          // .filter((e) => e.node.site.id !== p.site.id)
          .map((e) => ({
            id: toId(p.site.name, p.Container, toProjectTz(new Date(p.StartTime))),
            populationId: p.PopulationId,
            site: p.site.name,
            container: p.Container,
            group: `${p.site.name} - ${p.Container}`,
            date: toProjectTz(new Date(p.StartTime)),
            fromSite: e.node.site.name,
            fromContainer: e.node.Container,
            fromId: toId(e.node.site.name, e.node.Container, toProjectTz(new Date(e.node.EndTime))),
            count: e.properties.TransferredCount,
            biomass: e.properties.TransferredBiomassKg,
            avgBiomass: e.properties.TransferredAvgBiomassKg,
            tags: e.properties?.Tags,
            type: 'transfer-in' as const
          }))
      );
    });

    const outputPopulationEvents = data.populations.flatMap((p) => {
      return p.relatedPopulationsOutConnection.edges.map((e) => ({
        id: toId(p.site.name, p.Container, toProjectTz(new Date(p.EndTime))),
        populationId: p.PopulationId,
        site: p.site.name,
        container: p.Container,
        group: `${p.site.name} - ${p.Container}`,
        date: toProjectTz(new Date(p.EndTime)),
        toSite: e.node.site.name,
        toContainer: e.node.Container,
        toId: toId(e.node.site.name, e.node.Container, toProjectTz(new Date(e.node.StartTime))),
        count: e.properties.TransferredCount,
        biomass: e.properties.TransferredBiomassKg,
        avgBiomass: e.properties.TransferredAvgBiomassKg,
        tags: e.properties?.Tags,
        type: 'transfer-out' as const
      }));
    });
    // Westside-104-24-05-02-Rant-Point-109-24-05-02
    // Westside-104-24-05-02-Rant-Point-108-24-05-02

    return [...stockEvents, ...inputPopulationEvents, ...outputPopulationEvents];
  }, [data]);

  const classifyEvents = (
    events: PopulationEvent[]
  ): { type: PopulationEventType; icon: IconType } => {
    const toLocation = uniq(events.map((e) => e.toId).filter((s) => s));
    const fromLocation = uniq(events.map((e) => e.fromId).filter((s) => s));
    if (events.every((e) => e.type === 'stock')) {
      return {
        type: 'stock',
        icon: HiOutlineTruck
      };
    } else if (events.every((e) => e.type === 'transfer-in')) {
      return {
        type: 'transfer-in',
        icon: TbArrowDownRightCircle
      };
    } else if (events.every((e) => e.type === 'transfer-out')) {
      return {
        type: 'transfer-out',
        icon: TbArrowUpRightCircle
      };
    } else if (events.every((e) => e.type === 'harvest')) {
      return {
        type: 'harvest',
        icon: GiFishingNet
      };
      // It's a split when fish are going out to two or more distinct sites
    } else if (toLocation.length >= 2) {
      return {
        type: 'split',
        icon: MdCallSplit
      };
      // It's a merge when fish are coming in from two or more distinct sites
    } else if (fromLocation.length >= 2) {
      return {
        type: 'merge',
        icon: MdMerge
      };
    } else {
      return {
        type: 'other',
        icon: TbCircle
      };
    }
  };

  const events = useMemo(() => {
    const dateGrouped = groupBy(transferEvents, (e) => `${e.group}-${format(e.date, 'yy-MM-dd')}`);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(dateGrouped).map(([_dateGroupKey, tEvents]) => {
      const eventType = classifyEvents(tEvents);

      return {
        id: tEvents[0].id,
        date: tEvents[0].date,
        group: tEvents[0].group,
        type: eventType.type,
        details: () => (
          <PopulationEventsDetails primaryEventType={eventType.type} events={tEvents} />
        ),
        icon: eventType.icon
      };
    });
  }, [transferEvents]);

  const arrows = useMemo(() => {
    const eventIds = transferEvents.map((te) => te.id);
    const validArrowEvents = transferEvents
      .filter((e) => eventIds.includes(e?.toId))
      .filter((e) => e.id !== e.toId);

    return <PopulationEventArrows events={validArrowEvents} />;
  }, [transferEvents]);

  const initialPopulations = useMemo(() => {
    if (!existingFishGroup) return [];
    return populationEventData.filter((ped) =>
      existingFishGroup.fishGroup.populationIds.includes(ped.id)
    );
  }, [populationEventData, existingFishGroup]);

  const loadMorePopulations = () => setLookBackMonths(lookBackMonths + 6);

  const onSaveFishGroup = async ({
    name,
    populationIds
  }: {
    name: string;
    populationIds: string[];
  }) => {
    const mutation = isNew ? createFishGroupMutation : updateFishGroupMutation;
    try {
      await mutation({
        variables: {
          id: isNew ? undefined : existingFishGroup.fishGroup.id,
          fishGroup: {
            name,
            populationIds,
            projectId: project.id
          }
        },
        refetchQueries: ['fishGroups', 'GetFishGroups']
      });
      toast({
        status: 'success',
        description: 'Success saving fish group.'
      });
      navigate(`/project/${project.id}/fish-groups`);
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error saving fish group.'
      });
    }
  };

  if (error) {
    console.error(error);
  }

  return (
    <VStack w="100%">
      <Heading>{isNew ? 'New Fish Group' : existingFishGroup?.fishGroup?.name}</Heading>

      {loading ? (
        <Skeleton minH="600px" w="100%" />
      ) : error ? (
        <Notice noticeColor={'red.500'}>
          <Text>Error loading populations.</Text>
        </Notice>
      ) : data?.populations?.length === 0 ? (
        <Notice>
          <Text>No populations available.</Text>
        </Notice>
      ) : (
        <>
          <Text>
            Use the timeline below to create fish group populations. Use <Kbd>Shift</Kbd>+
            <Kbd>Scroll</Kbd> to move horizontally through time.
          </Text>
          <HStack color="gray.600" w="100%" justifyContent="space-evenly">
            <HStack>
              <HiOutlineTruck style={{ fontSize: '24px' }} />
              <Text>Stocking</Text>
            </HStack>
            <HStack>
              <TbArrowDownRightCircle style={{ fontSize: '24px' }} />
              <Text>Transfer in</Text>
            </HStack>
            <HStack>
              <TbArrowUpRightCircle style={{ fontSize: '24px' }} />
              <Text>Transfer out</Text>
            </HStack>
            <HStack>
              <MdCallSplit style={{ fontSize: '24px' }} />
              <Text>Split</Text>
            </HStack>
            <HStack>
              <MdMerge style={{ fontSize: '24px' }} />
              <Text>Merge</Text>
            </HStack>
            <HStack>
              <GiFishingNet style={{ fontSize: '24px' }} />
              <Text>Harvest</Text>
            </HStack>
            <HStack>
              <TbCircle style={{ fontSize: '24px' }} />
              <Text>Other / Unknown</Text>
            </HStack>
          </HStack>
          <PopulationsTimeline
            populations={populationEventData}
            initialName={existingFishGroup?.fishGroup?.name}
            initialPopulations={initialPopulations}
            events={events}
            arrows={arrows}
            onSaveFishGroup={onSaveFishGroup}
            onLoadMore={loadMorePopulations}
          />
        </>
      )}
    </VStack>
  );
};

export default FishGroupsEdit;
