import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { useContext, useMemo } from 'react';
import { RiBug2Line } from 'react-icons/ri';

const MotileLicePerFishStat: HeadlineStat<'motileLicePerFish'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { weeklyLiceResultSet, weeklyLiceIsLoading } = useContext(HeadlineDataContext);

  const avgLicePerFish = useMemo(() => {
    return weeklyLiceResultSet?.rawData()[0]['TessLice.avgLicePerFish'];
  }, [weeklyLiceResultSet]);

  return (
    <Stat
      isLoading={weeklyLiceIsLoading}
      key="motile-lice-per-fish"
      cyId="headline-stat"
      label="Motile Lice (lice/fish)"
      timeLabel="Last 7 days"
      value={safeFormat(avgLicePerFish)}
      icon={
        <Box padding="5px" color="blue.500">
          <RiBug2Line size={20} />
        </Box>
      }
      href={site ? `/project/${project.id}/site/${site.id}/sea-lice#motile-per-fish` : undefined}
      {...props}
    />
  );
};

MotileLicePerFishStat.statName = 'motileLicePerFish';
MotileLicePerFishStat.requiredDatasets = ['lice'];

export default MotileLicePerFishStat;
