import { DateRange, Filter } from '@cubejs-client/core';
import { FishGroupSpan } from 'components/Layouts/FishGroupsLayout';
import { clamp, isBefore, subMonths } from 'date-fns';
import { Site } from 'graphql/generated';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { ProjectContext } from './ProjectContext';

export type QueryContextType<T = any> = {
  contextFiltersFor: ({
    cube,
    lookupCube,
    dateRange
  }: {
    cube: string;
    lookupCube?: string;
    dateRange?: [Date, Date];
  }) => Filter[];
  contextDimensionsFor: ({ cube, lookupCube }: { cube: string; lookupCube?: string }) => string[];
  datumKey: (data: T, dimensions: string[]) => string;
  datumKeyToTraceName: (groupingMoniker: string, options?: { includesDepth?: boolean }) => string;
  contextDateRange: (dateRange: DateRange) => DateRange | null;
  mode: 'site' | 'project' | 'fishGroup';
  staleFishGroup: boolean;
};

const QueryContext = createContext<QueryContextType | null>(null);

type QueryFilterProviderProps = {
  site?: Site;
  fishGroupSpans?: FishGroupSpan[];
};

const QueryContextProvider: FC<QueryFilterProviderProps> = ({
  site,
  fishGroupSpans = [],
  children
}) => {
  const project = useContext(ProjectContext);

  const contextDateRange = (dateRange: DateRange) => {
    return dateRange;
  };

  const contextDimensionsFor = useCallback(
    ({ cube, lookupCube }: { cube: string; lookupCube?: string }) => {
      const lookup = lookupCube ? lookupCube : `${cube}Lookup`;

      return site
        ? [`${lookup}.sublocation`]
        : fishGroupSpans.length > 0
          ? ['Site.id', `${lookup}.sublocation`]
          : ['Site.id'];
    },
    [site, fishGroupSpans]
  );

  const contextFiltersFor = useCallback(
    ({
      cube,
      lookupCube,
      dateRange
    }: {
      cube: string;
      lookupCube?: string;
      dateRange?: [Date, Date];
    }) => {
      const lookup = lookupCube ? lookupCube : `${cube}Lookup`;

      const filters: Filter[] = [];
      if (site) {
        filters.push({
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        });
      }
      if (fishGroupSpans.length > 0) {
        filters.push({
          or: fishGroupSpans.map((fgs) => {
            const sublocation = fgs.sublocation.replace(/-(0+)(\d+)/, (_, _zeroes, digits) => {
              const number = parseInt(digits, 10);
              return number < 10 ? `-0${number}` : `-${number}`;
            });
            let start = new Date();
            let end = new Date();
            if (dateRange) {
              start = clamp(fgs.start, { start: dateRange[0], end: dateRange[1] });
              end = clamp(fgs.end, { start: dateRange[0], end: dateRange[1] });
            } else {
              start = fgs.start;
              end = fgs.end;
            }

            return {
              and: [
                {
                  member: `${cube}.measuredAt`,
                  operator: 'inDateRange' as const,
                  values: [start.toISOString(), end.toISOString()]
                },
                {
                  member: `${lookup}.sublocation`,
                  operator: 'equals' as const,
                  values: [sublocation]
                },
                {
                  member: `${lookup}.siteId`,
                  operator: 'equals' as const,
                  values: [fgs.siteSmbId.toString()]
                }
              ]
            };
          })
        });
      }

      return filters;
    },
    [site, fishGroupSpans]
  );

  const datumKey = <T,>(data: T, dimensions: string[]): string => {
    return dimensions.map((d) => data[d]).join('-');
  };

  const siteAndCageName = useCallback(
    (groupingMoniker: string) => {
      const firstDash = groupingMoniker.indexOf('-');
      const sublocationWithoutSite = groupingMoniker.slice(firstDash + 1);

      return `${project.siteNameMappings[groupingMoniker.split('-')[0]]} ${sublocationWithoutSite}`;
    },
    [project]
  );

  const datumKeyToTraceName = useCallback(
    (groupingKey: string, options?: { includesDepth?: boolean }) => {
      const dashSegments = groupingKey.split('-');

      let traceName = site
        ? groupingKey
        : fishGroupSpans.length > 0
          ? siteAndCageName(groupingKey)
          : project.siteNameMappings[dashSegments.length ? dashSegments[0] : groupingKey];

      if (options?.includesDepth) {
        if (/-5$/.test(groupingKey)) {
          traceName = traceName.replace(/-5$/, '');
        } else {
          traceName = `${traceName}-${dashSegments[dashSegments.length - 1]}m`;
        }
      }

      return traceName;
    },
    [site, siteAndCageName, fishGroupSpans, project]
  );

  const staleFishGroup = useMemo(
    () => fishGroupSpans.map((fgs) => fgs.end).every((d) => isBefore(d, subMonths(new Date(), 1))),
    [fishGroupSpans]
  );

  return (
    <QueryContext.Provider
      value={{
        contextFiltersFor,
        contextDimensionsFor,
        datumKey,
        datumKeyToTraceName,
        contextDateRange,
        mode: site ? 'site' : fishGroupSpans?.length > 0 ? 'fishGroup' : 'project',
        staleFishGroup
      }}>
      {children}
    </QueryContext.Provider>
  );
};

export { QueryContextProvider, QueryContext };
