import { gql, useMutation, useQuery } from '@apollo/client';
import { DeleteIcon } from '@chakra-ui/icons';
import { HStack, VStack, Text, Button, Box, AlertIcon, Alert } from '@chakra-ui/react';
import { useEditor } from '@tiptap/react';
import FancyEditor, { DEFAULT_EXTENSIONS } from 'components/Forms/FancyEditor';
import { QueryContextProvider } from 'contexts/QueryContext';
import { format } from 'date-fns';
import { GetProjectReportQuery } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const GET_PROJECT_REPORT = gql`
  query GetProjectReportView($reportId: Int!) {
    projectReport(reportId: $reportId) {
      id
      type
      name
      description
      content
      created
      url
      published
      parentProjectReportId
      createdBy {
        oryId
        name
      }
    }
  }
`;

const DELETE_PROJECT_REPORT = gql`
  mutation DeleteProjectReport($projectReportId: Int!) {
    deleteProjectReport(projectReportId: $projectReportId)
  }
`;

const Info = ({ name, value }: { name: string; value: string }) => {
  return (
    <VStack spacing="0" alignItems="start">
      <Text fontSize="sm" as="b" color="gray.500">
        {name}
      </Text>
      <Text>{value}</Text>
    </VStack>
  );
};

const ReportsView = () => {
  const { reportId } = useParams();

  const { data, loading, error } = useQuery<GetProjectReportQuery>(GET_PROJECT_REPORT, {
    variables: { reportId: Number(reportId) }
  });

  console.log(loading);
  console.log(error);

  const [deleteProjectReport] = useMutation(DELETE_PROJECT_REPORT, {
    refetchQueries: ['GetProjectReports']
  });

  const editor = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    content: data?.projectReport?.content,
    editable: false,
    autofocus: false
  });

  useEffect(() => {
    if (data?.projectReport && !loading) {
      editor.commands.setContent(data.projectReport.content);
    }
  }, [data, loading]);

  const [isVerifyingDelete, setDeleteVerification] = useState(false);

  const verifyDelete = async () => {
    if (!isVerifyingDelete) {
      setDeleteVerification(true);
    } else {
      await deleteProjectReport({ variables: { projectReportId: data?.projectReport?.id } });
    }
  };

  return (
    <QueryContextProvider>
      <HStack w="100%" alignItems="start">
        <VStack w="20%" alignItems="start" justifyContent="start" spacing="5">
          <Info name="Type" value={data?.projectReport?.type} />
          <Info name="Description" value={data?.projectReport?.description} />
          <Info name="Created By" value={data?.projectReport?.createdBy?.name} />
          <Info
            name="Published"
            value={
              data?.projectReport?.published
                ? format(new Date(data?.projectReport?.published), 'PP')
                : 'Draft'
            }
          />

          <Button
            data-cypress="delete-report"
            colorScheme={isVerifyingDelete ? 'white' : 'red'}
            color={isVerifyingDelete ? 'red.500' : 'white'}
            borderColor={isVerifyingDelete && 'red.500'}
            border={isVerifyingDelete && '1px solid'}
            leftIcon={<DeleteIcon />}
            onClick={verifyDelete}>
            {isVerifyingDelete ? 'Confirm Delete?' : 'Delete'}
          </Button>
        </VStack>
        <Box w="100%">
          {!data?.projectReport.published && (
            <Alert status="info" variant="left-accent">
              <AlertIcon mb="5px" />
              This report is currently in the draft stage. It is not finalized and may change!
            </Alert>
          )}
          <FancyEditor editor={editor} />
        </Box>
      </HStack>
    </QueryContextProvider>
  );
};

export default ReportsView;
