import { InfoOutlineIcon } from '@chakra-ui/icons';
import { HStack, Text, Tooltip } from '@chakra-ui/react';
import Select from 'react-select';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

export type ControllerInputs = {
  sublocationOptions?: { value: string; label: string }[];
};

const Controller = ({
  setChartSettings,
  chartSettings,
  controllerSettings,
  chartInputs: { sublocationOptions }
}: ChartController<ChartSettings, ControllerInputs>) => {
  return (
    <HStack justify="center" pb="10px">
      {controllerSettings?.showSublocations && (
        <>
          <Tooltip label="Fish count summed across the selected sublocations" fontSize="md">
            <InfoOutlineIcon fontSize="md" mb="5px" />
          </Tooltip>
          <Text>Sublocation </Text>
          <Select
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                width: '300px'
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                textAlign: 'left'
              })
            }}
            defaultValue={{ value: 'All', label: 'All' }}
            onChange={(x) => setChartSettings({ ...chartSettings, selectedSublocation: x.value })}
            options={sublocationOptions}
            value={sublocationOptions.find((lo) => lo.value === chartSettings?.selectedSublocation)}
          />
        </>
      )}
    </HStack>
  );
};

export default Controller;
