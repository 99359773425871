import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartInputs, ChartSettings } from './Chart';

const Controller = ({
  setChartSettings,
  chartSettings,
  chartInputs: { sublocationOptions }
}: ChartController<ChartSettings, ChartInputs>) => {
  const dimensionOptions = [
    { label: 'Feed Name', value: 'name' },
    { label: 'Product Name', value: 'product' },
    { label: 'Supplier', value: 'manufacturer' },
    { label: 'Feed Size', value: 'feedSizeMm' }
  ];

  return (
    <ChartSwitchPanel
      selects={[
        {
          value: sublocationOptions.filter((s) =>
            chartSettings.selectedSublocations.map((s) => s.value).includes(s.value)
          ),
          minWidth: '600px',
          width: '100%',
          options: sublocationOptions,
          label: 'Sublocations',
          isMulti: true,
          isClearable: true,
          onChange: (options: { label: string; value: any }[]) => {
            const selectedSublocations =
              options?.length === 0
                ? [{ label: 'All', value: 'All' }]
                : options.length > 1 && options.map((o) => o.value).includes('All')
                  ? options.filter((o) => o.value !== 'All')
                  : options;
            setChartSettings({
              ...chartSettings,
              selectedSublocations
            });
          }
        },
        {
          value: dimensionOptions.find((dopt) => dopt.value === chartSettings.dimension),
          minWidth: '150px',
          options: dimensionOptions,
          label: 'Dimension',
          onChange: (option: { label: string; value: ChartSettings['dimension'] }) => {
            setChartSettings({
              ...chartSettings,
              dimension: option.value
            });
          }
        }
      ]}
    />
  );
};

export default Controller;
