import { gql, useQuery } from '@apollo/client';
import { Center, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { RenderError } from 'components/Pages/RenderError';
import { FishGroup, Population } from 'graphql/generated';
import { FC, useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Notice } from 'components/Notice';
import { QueryContextProvider } from 'contexts/QueryContext';
import { Link as ReactLink } from 'react-router-dom';
import { ProjectContext } from 'contexts/ProjectContext';
import { ArrowLeftIcon } from '@chakra-ui/icons';

export type FishGroupSpan = {
  start: Date;
  end: Date;
  siteSmbId: number;
  sublocation: string;
};

export type FishGroupsOutletContext = {
  fishGroup: FishGroup;
  fishGroupSpans: FishGroupSpan[];
};

// because of the federation issues with neo, use 2 queries :(
const GET_FISH_GROUP_QUERY = gql`
  query GetFishGroup($id: Int!) {
    fishGroup(id: $id) {
      id
      name
      populationIds
    }
  }
`;

const GET_FISH_GROUP_POPULATIONS = gql`
  query GetFishGroupPopulations($populationIds: [String!]!) {
    populations(where: { PopulationId_IN: $populationIds }) {
      SmbId
      site {
        id
        name
      }
      Container
      StartTime
      EndTime
    }
  }
`;

export const TabErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <Center width={'100vw'} height={'100vh'}>
        <RenderError />
      </Center>
    }
    showDialog={false}>
    {children}
  </ErrorBoundary>
);

const FishGroupsLayout = () => {
  const project = useContext(ProjectContext);
  const { fishGroupId } = useParams();
  const {
    data: fishGroupData,
    loading: fishGroupLoading,
    error: fishGroupError
  } = useQuery(GET_FISH_GROUP_QUERY, { variables: { id: Number(fishGroupId) } });
  const {
    data: populationData,
    loading: populationLoading,
    error: populationError
  } = useQuery(GET_FISH_GROUP_POPULATIONS, {
    variables: { populationIds: fishGroupData?.fishGroup.populationIds },
    skip: !fishGroupData?.fishGroup.populationIds
  });

  if (fishGroupLoading || populationLoading) return <Skeleton minH="600px" w="100%" />;

  if (fishGroupError || populationError)
    return (
      <Notice noticeColor="red.500" w="100%">
        <Text>There was an error loading your fish group.</Text>
      </Notice>
    );

  const fishGroupSpans: FishGroupSpan[] =
    populationData?.populations.map((p: Population) => ({
      start: new Date(p.StartTime),
      end: p.EndTime ? new Date(p.EndTime) : new Date(),
      siteSmbId: p.SmbId,
      sublocation: `cage-${p.Container}`
    })) ?? [];

  return (
    <VStack w="100%">
        <ReactLink
          style={{ alignSelf: 'start', color: 'var(--chakra-colors-blue-500)' }}
          to={`/project/${project.id}/fish-groups`}>
          <ArrowLeftIcon pr="5px" />
          Return to Fish Groups
        </ReactLink>
      <Heading>{fishGroupData?.fishGroup.name}</Heading>
      <QueryContextProvider fishGroupSpans={fishGroupSpans}>
        <Outlet context={{ fishGroup: fishGroupData.fishGroup, fishGroupSpans }} />
      </QueryContextProvider>
    </VStack>
  );
};

export default FishGroupsLayout;
