import { Skeleton } from '@chakra-ui/react';
import { QueryContext } from 'contexts/QueryContext';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max, min, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps } from '../types';

type SfrDatum = {
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
  'Biology.specificFeedRate': number;
};

type SfrStructure = {
  [sublocation: string]: {
    [measuredAt: string]: {
      sfr: number;
    };
  };
};

const Chart = ({
  settings,
  granularity = 'day',
  dateRange = 'Last 30 days',
  chartRange,
  skip,
  onDataLoaded
}: BaseChartProps) => {
  const {
    contextFiltersFor,
    contextDimensionsFor,
    datumKey,
    datumKeyToTraceName,
    contextDateRange
  } = useContext(QueryContext);
  const dimensions = contextDimensionsFor({ cube: 'Biology' });

  const transform = (data: SfrDatum[]): SfrStructure => {
    // Create the sublocation data
    const bySublocation = groupBy(data, (d) => datumKey(d, dimensions));
    const output = Object.keys(bySublocation).reduce((acc, sublocation: string) => {
      const byMeasuredAt = bySublocation[sublocation].reduce((subAcc, datum) => {
        subAcc[datum[`Biology.measuredAt.${granularity}`]] = {
          sfr: datum['Biology.specificFeedRate']
        };
        return subAcc;
      }, {});
      acc[sublocation] = byMeasuredAt;
      return acc;
    }, {});

    // Calc the site average
    const byMeasuredAt = groupBy(data, (d) => d[`Biology.measuredAt.${granularity}`]);
    output['site-average'] = Object.keys(byMeasuredAt).reduce((acc, measuredAt: string) => {
      const sfrSum = byMeasuredAt[measuredAt].reduce(
        (subAcc, datum) => subAcc + datum['Biology.specificFeedRate'],
        0
      );
      acc[measuredAt] = {
        sfr: sfrSum / byMeasuredAt[measuredAt].length
      };

      return acc;
    }, {});

    return output;
  };

  const graph = (data: SfrStructure): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
    // Move the 'site' location to the end so its more visible
    sublocations.push(sublocations.shift());
    const pallet = createLocationPallet({ locations: sublocations });

    const plotData: Data[] = sublocations.map((subloc) => {
      const name = datumKeyToTraceName(subloc);
      return {
        type: 'scatter',
        mode: 'lines+markers',
        name: subloc == 'site-average' ? 'Average' : name,
        x: Object.keys(data[subloc]),
        y: Object.values(data[subloc]).flatMap((d) => d.sfr),
        marker: {
          color: pallet[subloc]
        },
        line: {
          dash: subloc.includes('site') ? 'dash' : 'solid',
          width: subloc.includes('site') ? 3 : 2
        },
        showlegend: true,
        hovertemplate: '<b>%{y:0.2f}</b>'
      };
    });

    let yMin = undefined;
    //@ts-ignore
    if (plotData.some((s) => s.y.some((value) => value < -1))) {
      yMin = -1;
    } else {
      //@ts-ignore
      yMin = min(plotData.flatMap((s) => s.y));
      yMin = yMin - yMin * 0.1;
    }

    let yMax = undefined;
    //@ts-ignore
    if (plotData.some((s) => s.y.some((value) => value > 5))) {
      yMax = 5;
    } else {
      //@ts-ignore
      yMax = max(plotData.flatMap((s) => s.y));
      yMax = yMax + yMax * 0.1;
    }

    const locationLabel = settings?.site ? 'Cage' : 'Site';
    const title = settings?.site
      ? `Specific Feed Rate - ${settings.site.name}`
      : `Specific Feed Rate - All Sites`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25
      },
      yaxis: {
        title: {
          text: `Specific Feeding Rate By ${locationLabel}`
        },
        range: [yMin, yMax]
      },
      xaxis: {
        range: chartRange,
        title: `${minDate} - ${maxDate} by ${granularity}`
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.specificFeedRate'],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange: contextDateRange(dateRange)
        }
      ],
      dimensions: contextDimensionsFor({ cube: 'Biology' }),
      filters: contextFiltersFor({ cube: 'Biology' }),
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      onDataLoaded,
      dependencies: {
        chartRange
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }
  const noData = plot?.data[0]['x'].length == 0;
  return !noData ? (
    <Plot className="w-100" useResizeHandler={true} {...plot} />
  ) : noData && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default Chart;
