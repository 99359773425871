import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { GiFishbone } from 'react-icons/gi';

const MortalityCountDailyStat: HeadlineStat<'mortalityCountDaily'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { recencyMoniker, weeklyBiologyResultSet, weeklyBiologyIsLoading } =
    useContext(HeadlineDataContext);

  const latestBio = useMemo(() => {
    const set = weeklyBiologyResultSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set[0]['Biology.measuredAt'];
    return set.filter((d) => d['Biology.measuredAt'] === latest);
  }, [weeklyBiologyResultSet]);

  const dailyMorts = useMemo(() => {
    // If today's date doesn't have data, look back a day.
    const set = weeklyBiologyResultSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set.find(
      (d) => d['Biology.mortalityCount'] && d['Biology.mortalityCount'] !== 0
    );

    const allOnDate = set.filter((d) => d['Biology.measuredAt'] === latest?.['Biology.measuredAt']);

    return {
      date: latest?.['Biology.measuredAt'],
      sum: sum(allOnDate.map((d) => d['Biology.mortalityCount']))
    };
  }, [latestBio]);

  return (
    <Stat
      isLoading={weeklyBiologyIsLoading}
      key="morts-count-daily"
      cyId="headline-stat"
      label="Last Mortality (count)"
      value={safeFormat(dailyMorts?.sum)}
      timeLabel={recencyMoniker(new Date(dailyMorts?.date))}
      icon={
        <Box padding="5px" color="blue.500">
          <GiFishbone size={20} />
        </Box>
      }
      href={
        site ? `/project/${project.id}/site/${site.id}/fish-health#absolute-mortality` : undefined
      }
      {...props}
    />
  );
};

MortalityCountDailyStat.statName = 'mortalityCountDaily';
MortalityCountDailyStat.requiredDatasets = ['biology'];

export default MortalityCountDailyStat;
