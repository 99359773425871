import { Skeleton } from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
// import { createLocationPallet } from 'shared/functions/colorPallets';
import { gql } from '@apollo/client';
// import { TransferHistory } from 'graphql/neoQueries';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { useQuery } from '@apollo/client';
import { TransferHistoryQueryResult, Population } from 'graphql/generated';
import { locationToIndex } from 'shared/functions/location';
import { createLocationPallet } from 'shared/functions/colorPallets';

export type ChartSettings = BaseChartSettings & {
  selectedSublocation?: { label: string; value: string };
  useBiomass: boolean;
};

export type ChartInputs = {
  sublocations?: { value: string; label: string }[];
};

const Chart = ({
  dateRange = 'Last 2 years',
  skip,
  settings: { useBiomass, selectedSublocation, project, ...settings },
  control
  // onDataLoaded
}: BaseChartProps<ChartSettings, ChartInputs>) => {
  function unpackPopulationsIn(population: Population) {
    const popInfo = population.PopulationId
      ? {
          [population.PopulationId]: {
            Site: population.site.name,
            Container: population.Container,
            StartTime: population.StartTime,
            EndTime: population.EndTime,
            relatedPopulationsInConnection: population.relatedPopulationsInConnection,
            relatedInputs: population.relatedInputs,
            relatedInputsConnection: population.relatedInputsConnection
          }
        }
      : {};
    if (population.relatedPopulationsIn?.length == 0 || !population.relatedPopulationsIn) {
      return popInfo;
    } else {
      const ress = population.relatedPopulationsIn.flatMap((d) => {
        return unpackPopulationsIn(d);
      }, {});
      return Object.keys(popInfo).length == 0 ? ress : [popInfo, ...ress];
    }
  }
  function TransferQuery(depth: number): string {
    const populationChunk = `
      SmbId
      PopulationId
      site {
        id
        name
      }
      Container
      StartTime
      EndTime
      relatedInputs {
        SmbId
        PopulationId
        Site
        Container
        StartTime
        InputCount
      }
      relatedInputsConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredCount
            SourcePop
            DestPop
          }
        }
      }
      relatedPopulationsInConnection {
          edges {
            properties {
              TransferredBiomassKg
              TransferredCount
              SourcePop
              DestPop
            }
          }
        }
    `;
    let populationQuery = `
    ${populationChunk}  
      relatedPopulationsIn {
        ${populationChunk}
    `;
    for (let i = 0; i <= depth; i++) {
      populationQuery = `
        ${populationQuery}
        relatedPopulationsIn {
          ${populationChunk}
      `;
    }
    populationQuery = `
    query TransferHistory($SmbId: Int = 71, $Container: String = "101") {
      populations(where: { Container: $Container, SmbId: $SmbId, EndTime: null }) {
      ${populationQuery}
    ${'}'.repeat(depth + 4)}
    `;
    return populationQuery;
  }

  const graph = (data: TransferHistoryQueryResult): PlotlyDataLayoutConfig => {
    const sources = [];
    const targets = [];
    const values = [];
    const customDataNodes = [];
    const customDataEdges = [];
    const linkLabels = [];
    //@ts-ignore
    const plotData = data.populations.map((rootPopulation) => {
      const unpackedPops = unpackPopulationsIn(rootPopulation);
      const popsDict = unpackedPops.reduce((acc, d) => {
        Object.keys(d).forEach((dd) => {
          acc[dd] = d[dd];
        }, {});
        return acc;
      }, {});
      // Create label array so we can use it for link lookups (source/target)
      const labels = Object.keys(popsDict);
      // This is the color of the Population nodes
      const nodeColors = Array(labels.length).fill('pink');
      // customDataNodes for Population nodes (StartTime/EndTime)
      Object.values(popsDict).map((d: Partial<Population>) => {
        customDataNodes.push(`<b>Duration:</b> ${((new Date(d.EndTime).getTime() - new Date(d.StartTime).getTime()) / (1000 * 60 * 60 * 24)).toFixed(2)} days
          <br><b>Start:</b>${d.StartTime}<br><b>End:</b>${d.EndTime}
          `);
        // Add any Input nodes to the popDict and associoated arrays
        d.relatedInputs.map((dd) => {
          labels.push(dd.PopulationId);
          customDataNodes.push(`<b>Input Time:</b> ${dd.StartTime}`);
          popsDict[dd.PopulationId] = dd;
          // This is the color of the Input nodes
          nodeColors.push('indigo');
        });
        // Generate links for Populations
        const edges = d.relatedPopulationsInConnection.edges;
        edges.forEach((edge) => {
          sources.push(labels.indexOf(edge.properties.SourcePop));
          targets.push(labels.indexOf(edge.properties.DestPop));
          values.push(
            useBiomass ? edge.properties.TransferredBiomassKg : edge.properties.TransferredCount
          );
          customDataEdges.push(
            `<b>Biomass (kg):</b> ${edge.properties.TransferredBiomassKg.toFixed(2)}
            <br><b>Count:</b> ${edge.properties.TransferredCount.toFixed(0)}`
          );
          linkLabels.push(`${edge.properties.DestPop}`);
        });
        // Generate links for Inputs
        const inputEdges = d.relatedInputsConnection.edges;
        inputEdges.forEach((edge) => {
          sources.push(labels.indexOf(edge.properties.SourcePop));
          targets.push(labels.indexOf(edge.properties.DestPop));
          values.push(
            useBiomass ? edge.properties.TransferredBiomassKg : edge.properties.TransferredCount
          );
          customDataEdges.push(
            `<b>Biomass (kg):</b> ${edge.properties.TransferredBiomassKg.toFixed(2)}
            <br><b>Count:</b> ${edge.properties.TransferredCount.toFixed(0)}}`
          );
          linkLabels.push(`${edge.properties.DestPop}`);
        });
      });
      const fullLabels = labels.map((d) => {
        return `${popsDict[d].Site}<br>Cage ${popsDict[d].Container}`;
      });
      const fullLinkLabels = linkLabels.map((d) => {
        return `${popsDict[d].Site}<br>Cage ${popsDict[d].Container}`;
      });
      const pallet = createLocationPallet({
        locations: uniq(fullLabels),
        palletType: 'site'
      });
      return {
        type: 'sankey',
        arrangement: 'snap', // "snap" | "perpendicular" |"freeform" | "fixed" )
        orientation: 'h',
        node: {
          label: fullLabels,
          color: nodeColors,
          // x: [],
          // y: [],
          align: 'justify',
          pad: 40,
          thickness: 25,
          customdata: customDataNodes,
          line: { color: 'white', width: '4' },
          hovertemplate: `
          <b>%{label}</b>
          <br>%{customdata}
          <br><b>Total ${useBiomass ? 'Biomass (kg)' : 'Count'}:</b> %{value}
          <br><extra></extra>
          `,
          hoverlabel: {
            align: 'left'
          }
        },
        link: {
          source: sources,
          target: targets,
          value: values,
          label: linkLabels,
          // arrowlen: 15,
          color: fullLinkLabels.map((d) => pallet[d]),
          customdata: customDataEdges,
          hovertemplate: `
          <b>%{label}</b>
          <br>%{customdata}
          <br><extra></extra>`,
          hoverlabel: {
            align: 'left'
          },
          line: { color: 'white', width: '1' }
        },
        customData: popsDict
      };
    });
    const layout = {
      height: 1000,
      showlegend: true
    };
    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };
  // Neo4j Query
  const {
    data: transferData,
    loading: isLoading,
    error: neoError
  } = useQuery<TransferHistoryQueryResult>(
    gql`
      ${TransferQuery(29)}
    `,
    {
      skip: skip,
      variables: {
        SmbId: Number(settings.site?.smbId),
        Container: selectedSublocation.value.replaceAll('cage-', '')
      }
    }
  );

  // Plot object
  const [plot, setPlot] = useState<PlotlyDataLayoutConfig>(null);

  // Await query result
  useEffect(() => {
    if (!isLoading && transferData) {
      const plot = graph(transferData);
      setPlot(plot);
    }
  }, [transferData, isLoading, neoError, selectedSublocation, useBiomass]);

  // Get sublocation data from Cube
  const locationDimension = settings.site?.smbId ? 'BiologyLookup.sublocation' : 'Site.id';
  const { resultSet } = useCubeQuery(
    {
      measures: [],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity: settings.site?.smbId ? 'hour' : 'hour',
          dateRange
        }
      ],
      dimensions: [locationDimension],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            }
          ]
        : [],
      timezone: project?.timezone
    },
    {
      skip: skip,
      subscribe: false
    }
  );
  // Set sublocations variable for dropdown.
  const sublocations = useMemo(() => {
    return (
      uniq(resultSet?.rawData().map((d) => d[locationDimension]))
        .sort((a, b) => locationToIndex(a) - locationToIndex(b))
        .map((d) => {
          return settings.site?.smbId
            ? { value: d, label: d }
            : {
                value: d,
                label: project?.siteNameMappings[d]
              };
        }) ?? []
    );
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (neoError) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project?.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 && !project?.freeTrial ? (
    <>
      {control ? control({ sublocations: sublocations }) : <></>}
      <NoData minH="450px" />
    </>
  ) : (
    <>
      {control ? control({ sublocations: sublocations }) : <></>}
      <Plot className="w-100" useResizeHandler={true} {...plot} />
    </>
  );
};

export default Chart;
