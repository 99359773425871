import { gql, useMutation, useQuery } from '@apollo/client';
import { VStack, Text, Skeleton } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import ReportTable from 'components/Reporting/ReportTable';
import { ProjectContext } from 'contexts/ProjectContext';
import { isAfter } from 'date-fns';
import { GetProjectReportsQuery } from 'graphql/generated';
import { groupBy } from 'lodash';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const CLONE_PROJECT_REPORT = gql`
  mutation CloneProjectReport($projectReportId: Int!) {
    cloneProjectReport(projectReportId: $projectReportId)
  }
`;

export const GET_PROJECT_REPORTS = gql`
  query GetProjectReports($projectId: Int!) {
    projectReports(projectId: $projectId) {
      id
      type
      name
      description
      content
      created
      url
      published
      parentProjectReportId
      createdBy {
        oryId
        name
      }
    }
  }
`;

const ReportingIndex = () => {
  const project = useContext(ProjectContext);
  const navigate = useNavigate();

  const [cloneProjectReport] = useMutation(CLONE_PROJECT_REPORT);

  const { data, loading, error } = useQuery<GetProjectReportsQuery>(GET_PROJECT_REPORTS, {
    variables: { projectId: project.id }
  });

  const groupedSortedProjectReports = useMemo(() => {
    if (!data?.projectReports) return [];

    const reportGroups = groupBy(data.projectReports, (r) => r?.parentProjectReportId ?? r?.id);

    const srgs = Object.entries(reportGroups).reduce(
      (acc, [parentReportId, childReports]) => {
        const reports = [...childReports];

        reports.sort((ra, rb) => (isAfter(new Date(ra.created), new Date(rb.created)) ? -1 : 1));
        acc[parentReportId] = reports;

        return acc;
      },
      {} as { [id: string]: GetProjectReportsQuery['projectReports'] }
    );

    const topOrdered = Object.entries(srgs);
    topOrdered.sort((groupOne, groupTwo) =>
      isAfter(new Date(groupOne[1][0].created), new Date(groupTwo[1][0].created)) ? -1 : 1
    );

    return topOrdered;
  }, [data]);

  return (
    <VStack w="100%">
      {error && (
        <Notice noticeColor="red.500">
          <Text>An error occured loading your reports.</Text>
        </Notice>
      )}

      {loading ? (
        <Skeleton h="600px" />
      ) : (
        <ReportTable
          //@ts-ignore
          groupedReports={groupedSortedProjectReports}
          onRowClick={(report) => navigate(`/project/${project.id}/reports/${report.id}/view`)}
          onCloneClick={async (report) => {
            const resp = await cloneProjectReport({
              variables: { projectReportId: report.id }
            });
            navigate(`/project/${project.id}/reports/${resp.data.cloneProjectReport}/edit/details`);
          }}
        />
      )}
    </VStack>
  );
};

export default ReportingIndex;
