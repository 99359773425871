import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings, ChartInputs } from './Chart';

const Controller = ({
  chartSettings,
  setChartSettings,
  controllerSettings,
  chartInputs: { sublocationOptions }
}: ChartController<ChartSettings, ChartInputs>) => {
  const unitOptions = [
    { label: 'Count', value: 'count' },
    { label: 'Biomass', value: 'biomass' },
    { label: 'Percent', value: 'percent' }
  ];

  const unitSelect = {
    value: unitOptions.find((o) => o.value === chartSettings.unit),
    width: '200px',
    options: unitOptions,
    label: 'Unit',
    onChange: (x: { label: string; value: any }) =>
      setChartSettings({
        ...chartSettings,
        unit: x.value
      })
  };

  return (
    <ChartSwitchPanel
      switches={[
        {
          switchProps: { isChecked: chartSettings.showByCauseName },
          left: { value: 'left', label: 'Cause Group' },
          right: { value: 'right', label: 'Cause' },
          toggle: (x) => setChartSettings({ ...chartSettings, showByCauseName: x.checked })
        }
      ]}
      selects={
        controllerSettings?.showSublocations
          ? [
              {
                value: sublocationOptions.find(
                  (s) => s.value === chartSettings.selectedSublocation
                ),
                width: '200px',
                options: sublocationOptions,
                label: 'Sublocation',
                onChange: (x: { label: string; value: any }) =>
                  setChartSettings({
                    ...chartSettings,
                    selectedSublocation: x.value
                  })
              },
              unitSelect
            ]
          : [unitSelect]
      }
    />
  );
};

export default Controller;
