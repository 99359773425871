import { gql, useMutation, useQuery } from '@apollo/client';
import { ArrowRightIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Heading, Skeleton, VStack, Text, IconButton, Box, useToast } from '@chakra-ui/react';
import ConfirmDeleteAlert from 'components/Modals/ConfirmDeleteAlert';
import { Notice } from 'components/Notice';
import { DataTable } from 'components/Tables/DataTable';
import { ProjectContext } from 'contexts/ProjectContext';
import { format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import { Column } from 'react-data-grid';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

const DELETE_FISH_GROUP = gql`
  mutation DeleteFishGroup($id: Int!) {
    deleteFishGroup(id: $id)
  }
`;


const GET_FISH_GROUPS_QUERY = gql`
  query GetFishGroups($projectId: Int!) {
    fishGroups(projectId: $projectId) {
      id
      name
      lastModified
    }
  }
`;

const FishGroupsIndex = () => {
  const project = useContext(ProjectContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { data, loading, error } = useQuery(GET_FISH_GROUPS_QUERY, {
    variables: { projectId: project.id }
  });

  const [deleteFishGroupMutation] = useMutation(DELETE_FISH_GROUP);

  const [deletingFishGroup, setDeletingFishGroup] = useState<number | null>();

  const deleteFishGroup = async () => {
    try {
      await deleteFishGroupMutation({ variables: { id: deletingFishGroup }, refetchQueries: ['GetFishGroups']});
      toast({
        status: 'success',
        description: 'Successfully deleted fish group.'
      });
    } catch(e) {
      toast({
        status: 'error',
        description: 'Error deleting fish group.'
      });
    } finally {
      setDeletingFishGroup(null);
    }
  };

  const columns: Column<any>[] = useMemo(
    () => [
      {
        id: 'name',
        name: 'name',
        key: 'name',
        Header: 'Name',
        accessor: 'name'
      },
      {
        id: 'lastModified',
        name: 'name',
        key: 'name',
        Header: 'Last Modified',
        accessor: 'lastModified',
        Cell: ({ value }: { value?: string }) => {
          return value ? format(new Date(value), 'PP p') : '-';
        }
      },
      {
        id: 'edit',
        name: 'edit',
        key: 'edit',
        Header: '',
        accessor: 'id',
        width: 30,
        Cell: ({ value }: { value: number }) => {
          return (
            <IconButton
              data-cypress="edit-population-button"
              onClick={(e) => {
                e.stopPropagation();
              }}
              as={ReactLink}
              to={`/project/${project.id}/fish-groups/${value}/timeline`}
              colorScheme="blue"
              float="right"
              aria-label="Edit Fish Group"
              icon={<EditIcon />}
            />
          );
        }
      },
      {
        id: 'delete',
        name: 'delete',
        key: 'delete',
        Header: '',
        accessor: 'id',
        width: 50,
        Cell: ({ value }: { value: number }) => {
          return (
            <IconButton
              float="right"
              colorScheme="red"
              icon={<DeleteIcon />}
              data-cypress="fish-group-delete-btn"
              onClick={(e) =>{ e.stopPropagation(); setDeletingFishGroup(value); }}
              aria-label={'Delete Fish Group'}/>
          );
        }
      }
    ],
    []
  );


  return (
    <VStack maxW="1000px" mx="auto">
      <Heading>Fish Groups</Heading>
      <ReactLink
        style={{ alignSelf: 'end' }}
        to={`/project/${project.id}/fish-groups/new/timeline`}>
        <Button colorScheme="blue" rightIcon={<ArrowRightIcon />}>
          Timeline
        </Button>
      </ReactLink>

      {loading ? (
        <Skeleton w="100%" minH="400px" />
      ) : error ? (
        <Notice noticeColor="red.500">
          <Text>There was an error loading your fish groups.</Text>
        </Notice>
      ) : (
        <Box w="100%" pb="50px">
        <DataTable
          columns={columns}
          data={data?.fishGroups ?? []}
          onRowClick={(row) => {
            navigate(`/project/${project.id}/fish-groups/${row.original.id}`);
          }}
        />
      </Box>
      )}
      <ConfirmDeleteAlert
        dialogHeader={<Text>Delete Fish Group?</Text>}
        isOpen={!!deletingFishGroup}
        onClose={() => setDeletingFishGroup(null)}
        onVerifyDelete={() => deleteFishGroup()}
      />
    </VStack>
  );
};

export default FishGroupsIndex;
