// @ts-strict
import { Container } from '@chakra-ui/react';
import DashboardMap from 'components/Map/DashboardMap';
import { useRef } from 'react';

const ProjectId = () => {
  const mapAreaContainerRef = useRef<HTMLDivElement>(null);
  return (
    <Container
      ref={mapAreaContainerRef}
      id="maparea"
      position={'relative'}
      height={'calc(-8rem + 100vh)'}
      maxWidth={'100%'}
      padding={0}
      marginTop={{
        base: '1em',
        lg: 0
      }}
      width={'100%'}>
      <DashboardMap />
    </Container>
  );
};

export default ProjectId;
