import { gql, useMutation, useQuery } from '@apollo/client';
import { useSteps, UseStepsReturn } from '@chakra-ui/react';
import {
  GetProjectReportQuery,
  GetProjectReportQueryResult,
  ProjectReport,
  ProjectReportType,
  UpdateProjectReportMutation,
  UpdateProjectReportMutationFn
} from 'graphql/generated';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import SeaStateReportBuilder from './SeaStateReportBuilder';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from 'contexts/ProjectContext';
import { Editor, useEditor } from '@tiptap/react';
import { DEFAULT_EXTENSIONS } from 'components/Forms/FancyEditor';
import { QueryContextProvider } from 'contexts/QueryContext';

const GET_PROJECT_REPORT = gql`
  query GetProjectReport($reportId: Int!) {
    projectReport(reportId: $reportId) {
      id
      type
      name
      description
      created
      url
      content
      published
      createdBy {
        oryId
        name
      }
    }
  }
`;

const UPDATE_PROJECT_REPORT = gql`
  mutation UpdateProjectReport($projectReportId: Int!, $projectReport: ProjectReportInput!) {
    updateProjectReport(projectReportId: $projectReportId, projectReport: $projectReport) {
      id
    }
  }
`;

export type ChartState = {
  [id: string]: 'loading' | 'loaded';
};

export type ReportTemplate = {
  id: ProjectReportType;
  label: string;
  icon?: JSX.Element;
  form: FC<{ projectReport: GetProjectReportQueryResult['data']['projectReport'] }>;
  isDisabled?: boolean;
};

// Blah blah something something useReducer
export type ReportingEditContextType = {
  reportId: number;
  setChartStates: (state: ChartState) => void;
  steps: { title: string; description: string }[];
  setActiveStep: (idx: number) => void;
  updateProjectReport: UpdateProjectReportMutationFn;
  selectedReportTemplate: ReportTemplate;
  setSelectedReportTemplate: (template: ReportTemplate) => void;
  reportInfo: ReportInfo;
  setReportInfo: (info: ReportInfo) => void;
  reportTemplates: ReportTemplate[];
  queryData: GetProjectReportQueryResult;
  showingFinalizeModal: boolean;
  setShowingFinalizeModal: (showing: boolean) => void;
  nextIsDisabled: boolean;
  editor: Editor;
} & UseStepsReturn;

export const ReportingEditContext = createContext<ReportingEditContextType>(null);

const steps = [
  {
    title: 'Report Type',
    description: 'Select the type of report you would like to generate',
    slug: 'select'
  },
  { title: 'Details', description: 'Fill in your report details', slug: 'details' },
  { title: 'Review', description: 'Review and verify the report', slug: 'review' }
];

const reportTemplates: ReportTemplate[] = [
  {
    id: ProjectReportType.Seastate,
    label: 'SeaState Report',
    icon: <Image src="/seastate.png" />,
    form: SeaStateReportBuilder
  },
  {
    id: ProjectReportType.Dfosealicrep,
    label: 'DFO - SEALICREP',
    icon: <Image src="https://www.canada.ca//etc/designs/canada/wet-boew/assets/wmms-blk.svg" />,
    form: function DfoSealicrepForm() {
      return <></>;
    },
    isDisabled: true
  }
];

export type ReportInfo = Pick<ProjectReport, 'name' | 'description'>;

type ReportingEditProviderProps = {
  reportId: number;
  stepSlug?: string;
};

export const ReportingEditProvider: FC<ReportingEditProviderProps> = ({
  reportId,
  stepSlug,
  children
}) => {
  const navigate = useNavigate();
  const project = useContext(ProjectContext);
  const [updateProjectReport] = useMutation<UpdateProjectReportMutation>(UPDATE_PROJECT_REPORT, {
    refetchQueries: ['GetProjectReport']
  });

  const queryData = useQuery<GetProjectReportQuery>(GET_PROJECT_REPORT, {
    variables: { reportId: Number(reportId) }
  });

  const editor = useEditor(
    {
      content: queryData?.data?.projectReport?.content,
      extensions: DEFAULT_EXTENSIONS,
      editable: stepSlug === 'details'
    },
    [queryData, stepSlug]
  );

  const [selectedReportTemplate, setSelectedReportTemplate] = useState(reportTemplates[0]);
  const [reportInfo, setReportInfo] = useState<ReportInfo>({
    description: queryData?.data?.projectReport?.description,
    name: queryData?.data?.projectReport?.name
  });

  useEffect(() => {
    if (queryData?.data?.projectReport?.content) {
      const report = queryData.data.projectReport;
      editor.commands.setContent(report?.content);
      setReportInfo({ name: report?.name, description: report?.description });
    }
  }, [queryData]);

  const [showingFinalizeModal, setShowingFinalizeModal] = useState(false);

  const stepIndex = stepSlug ? steps.findIndex((s) => s.slug === stepSlug) + 1 : 1;

  const stepProps = useSteps({
    index: stepIndex,
    count: steps.length
  });

  useEffect(() => {
    stepProps.setActiveStep(stepIndex);
  }, [stepIndex]);

  const goToNextSlug = () => {
    navigate(
      `/project/${project.id}/admin/reports/${reportId}/edit/${steps[stepIndex]?.slug ?? 'review'}`
    );
  };

  const goToPreviousSlug = () => {
    navigate(
      `/project/${project.id}/admin/reports/${reportId}/edit/${steps?.[stepIndex - 2]?.slug ?? 'select'}`
    );
  };

  const nextIsDisabled = useMemo(() => {
    if (stepIndex === 1) return false;

    if (stepIndex === 3) {
      return !reportInfo?.name;
    }
  }, [stepIndex, reportInfo]);

  return (
    <ReportingEditContext.Provider
      value={{
        reportId,
        steps,
        updateProjectReport,
        selectedReportTemplate,
        setSelectedReportTemplate,
        reportInfo,
        setReportInfo,
        reportTemplates,
        //@ts-ignore
        queryData,
        showingFinalizeModal,
        setShowingFinalizeModal,
        ...{ ...stepProps, goToNext: goToNextSlug, goToPrevious: goToPreviousSlug },
        nextIsDisabled,
        editor
      }}>
      <QueryContextProvider>
        {children}
      </QueryContextProvider>
    </ReportingEditContext.Provider>
  );
};
