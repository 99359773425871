import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig, PlotType } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { QueryContext } from 'contexts/QueryContext';
import { useContext } from 'react';

type MortalityDatum = {
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
  'Biology.mortalityCountSum': number;
  'Biology.mortalityWeightSum': number;
};

type MortalityStructure = {
  [sublocation: string]: {
    measuredAt: string;
    count: number;
    biomass: number;
  }[];
};

export type ChartSettings = BaseChartSettings & {
  showInBiomass?: boolean;
};

const Chart = ({
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  skip,
  settings: { showInBiomass, project, ...settings },
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const {
    contextFiltersFor,
    contextDimensionsFor,
    datumKey,
    datumKeyToTraceName,
    contextDateRange
  } = useContext(QueryContext);
  const dimensions = contextDimensionsFor({ cube: 'Biology' });

  const transform = (data: MortalityDatum[]): MortalityStructure => {
    const bySublocation = groupBy(data, (d) => datumKey(d, dimensions));
    return Object.keys(bySublocation).reduce((acc, subloc: string) => {
      acc[subloc] = bySublocation[subloc].map((d) => ({
        measuredAt: d[`Biology.measuredAt.${granularity}`],
        count: d['Biology.mortalityCountSum'],
        biomass: d['Biology.mortalityWeightSum']
      }));
      return acc;
    }, {});
  };

  const graph = (data: MortalityStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );

    const pallet = createLocationPallet({ locations: sublocations });
    const plotData = sublocations.map((subloc) => {
      const name = datumKeyToTraceName(subloc);
      return {
        type: 'bar' as PlotType,
        name,
        x: data[subloc].map((d) => d.measuredAt),
        y: data[subloc].map((d) => (showInBiomass ? d.biomass : d.count)),
        marker: {
          color: pallet[subloc]
        },
        hovertemplate: `<b>%{x}</b><br>${name}<br>${
          dependencies?.biomassToggle
            ? 'total biomass (kg): <b>%{y:.1f}</b>'
            : 'total count: <b>%{y:0.f}</b>'
        }<extra></extra>`
      };
    });

    const measurementLabel = showInBiomass ? 'Weight (kg)' : 'Count';
    const locationLabel = settings?.site ? 'Cage' : 'Site';
    const title = settings?.site
      ? `Absolute Mortality - ${settings.site.name}`
      : `Absolute Mortality - All Sites`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      barmode: 'stack',
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      yaxis: {
        title: `${measurementLabel} By ${locationLabel}`
      },
      xaxis: {
        range: chartRange,
        title: `${minDate} - ${maxDate} by ${granularity}`
      },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        y: 10,
        traceorder: 'normal'
      },
      hovermode: 'closest'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.mortalityCountSum', 'Biology.mortalityWeightSum'],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange: contextDateRange(dateRange)
        }
      ],
      dimensions: contextDimensionsFor({ cube: 'Biology' }),
      filters: contextFiltersFor({ cube: 'Biology' }),
      timezone: project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: { biomassToggle: showInBiomass, chartRange },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 && !project.freeTrial ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100 absolute-mortality-chart" useResizeHandler={true} {...plot} />
  );
};

export default Chart;
