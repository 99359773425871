import { Box, BoxProps, Text, Wrap } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import { HeadlineDataContextProvider } from 'contexts/HeadlineDataContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import useHeadlineStats, { HeadlineStatName } from 'hooks/useHeadlineStats';
import { sortBy, uniq } from 'lodash';
import { useContext } from 'react';

const FISH_GROUP_HEADLINES = [
  { key: 'totalFishCount', order: 3 },
  { key: 'averageWeightKg', order: 4 },
  { key: 'mortalityCountDaily', order: 5 },
  { key: 'mortalityCountWeekly', order: 6 },
  { key: 'topMortalityCauses', order: 7 },
  { key: 'feedUseKg', order: 8 },
  { key: 'sfrAvg', order: 9 },
  { key: 'motileLicePerFish', order: 10 },
  { key: 'latestPlanktonCells', order: 14 }
];

export const safeFormat = (value?: number, digits = 2, placeholder = '-') =>
  value ? Number(value.toFixed(digits)).toLocaleString() : placeholder;

const HeadlineStats = ({ site, ...props }: { site?: Site } & BoxProps) => {
  const project = useContext(ProjectContext);
  const stats = useHeadlineStats({
    statNames: sortBy(site?.headlineStats ?? FISH_GROUP_HEADLINES, 'order').map(
      (hs) => hs.key as HeadlineStatName
    )
  });

  return (
    <Box w="100%" m="1rem" textAlign="center" {...props}>
      {stats?.length ? (
        <HeadlineDataContextProvider
          site={site}
          project={project}
          requestedDatasets={uniq(stats.flatMap((s) => s.requiredDatasets))}>
          <Wrap p="5px" w="100%">
            {stats.map((StatComponent, index) => (
              <StatComponent key={index} project={project} site={site} />
            ))}
          </Wrap>
        </HeadlineDataContextProvider>
      ) : (
        <Notice h="100%">
          <Text>No headline tiles assigned.</Text>
        </Notice>
      )}
    </Box>
  );
};

export default HeadlineStats;
