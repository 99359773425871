import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { RiPercentLine } from 'react-icons/ri';

const SfrAvgStat: HeadlineStat<'sfrAvg'> = ({ project, site, ...props }: HeadlineStatProps) => {
  const { weeklyBiologyResultSet, weeklyBiologyIsLoading } = useContext(HeadlineDataContext);

  const sfrAvg = useMemo(() => {
    if (weeklyBiologyResultSet?.rawData()) {
      const nonZero = weeklyBiologyResultSet
        .rawData()
        .map((d) => d['Biology.specificFeedRateD'])
        .filter((sfr) => sfr > 0);
      return sum(nonZero) / nonZero.length;
    }
    return weeklyBiologyResultSet?.rawData()?.[0]['Biology.specificFeedRateD'];
  }, [weeklyBiologyResultSet]);

  return (
    <Stat
      isLoading={weeklyBiologyIsLoading}
      key="sfr-avg"
      cyId="headline-stat"
      label="SFR (avg)"
      timeLabel="Last 7 days"
      value={safeFormat(sfrAvg)}
      icon={
        <Box padding="6px" color="blue.500">
          <RiPercentLine size={18} />
        </Box>
      }
      href={site ? `/project/${project.id}/site/${site.id}/feed#sfr` : undefined}
      {...props}
    />
  );
};

SfrAvgStat.statName = 'sfrAvg';
SfrAvgStat.requiredDatasets = ['biology'];

export default SfrAvgStat;
