import { Heading, Text, HStack, StyleProps } from '@chakra-ui/react';
import InfoPopover from 'components/InfoPopover';
import Tile from 'components/Tile';
import { Project, Site } from 'graphql/generated';
import React, { ReactChild } from 'react';
import EventWrapper from './EventWrapper';

type ChartTileProps = {
  site?: Site;
  project?: Project;
  control?: ReactChild;
  heading?: ReactChild | string;
  isNew?: boolean;
  tooltip?: ReactChild | string;
  hash?: string;
  icon?: ReactChild;
};

const ChartTile: React.FC<ChartTileProps & StyleProps> = ({
  site,
  project,
  control,
  heading,
  isNew,
  tooltip,
  children,
  hash,
  icon,
  ...styles
}) => (
  <Tile id={hash} w="100%" mb="40px" pt="20px" pb="20px" {...styles}>
    {typeof heading == 'string' ? (
      <>
        <HStack justify="center" alignContent="center" mb="10px">
          {icon && { icon }}
          <Heading as="h5" size="lg">
            {heading}
          </Heading>
          {tooltip && <InfoPopover pb="3px">{tooltip}</InfoPopover>}
          {isNew && (
            <Text color="blue.500" fontSize="xs" pb="40px">
              New!
            </Text>
          )}
        </HStack>
      </>
    ) : (
      heading
    )}
    {control}
    {
      <EventWrapper site={site ? site : null} project={project ? project : null}>
        {children}
      </EventWrapper>
    }
  </Tile>
);

export default ChartTile;
