import { Box } from '@chakra-ui/react';
import Stat from 'components/Stat';
import { HeadlineDataContext } from 'contexts/HeadlineDataContext';
import { HeadlineStat, HeadlineStatProps, safeFormat } from 'hooks/useHeadlineStats';
import { sum } from 'lodash';
import { useContext, useMemo } from 'react';
import { GiFishbone } from 'react-icons/gi';

const MortalityCountWeeklyStat: HeadlineStat<'mortalityCountWeekly'> = ({
  project,
  site,
  ...props
}: HeadlineStatProps) => {
  const { weeklyBiologyResultSet, weeklyBiologyIsLoading } = useContext(HeadlineDataContext);

  const weeklyMorts = useMemo(() => {
    if (weeklyBiologyResultSet?.rawData()) {
      return sum(weeklyBiologyResultSet?.rawData().map((d) => d['Biology.mortalityCount']));
    }
  }, [weeklyBiologyResultSet]);

  return (
    <Stat
      isLoading={weeklyBiologyIsLoading}
      key="morts-count-weekly"
      cyId="headline-stat"
      label="Morts (count)"
      value={safeFormat(weeklyMorts)}
      timeLabel="Last 7 days"
      icon={
        <Box padding="5px" color="blue.500">
          <GiFishbone size={20} />
        </Box>
      }
      href={
        site ? `/project/${project.id}/site/${site.id}/fish-health#absolute-mortality` : undefined
      }
      {...props}
    />
  );
};

MortalityCountWeeklyStat.statName = 'mortalityCountWeekly';
MortalityCountWeeklyStat.requiredDatasets = ['biology'];

export default MortalityCountWeeklyStat;
